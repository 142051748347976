import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import shajs from 'sha.js';
import {
  ILightBoxData, IAdditionalDetails, EMIPlanBean, EmiPaymentsService,
  HeaderService, LightboxdataService, UserdataService,
  CnpdataService, fieldnames, PageTypes, IPaymentInfo, UPIScreens,
  IHierarchyData, ISessionData, ResponseCode,
  OtpResponseConstants, PaymentRolesService, ISplitTxnData, ITxnData, IUPIPayload, IValidateUPIPayload, IFetchVPAPayload, IDeleteVPAPayload
} from 'src/app/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-upi',
  templateUrl: './upi.component.html',
  styleUrls: ['./upi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UPIComponent implements OnInit {

  @Input()
  isEmiScreen = false;

  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('upiForm', { static: false })
  upiForm: NgForm;

  @ViewChild('cardA', null)
  cardAField: ElementRef;

  @ViewChild('input1', null)
  otp1Field: ElementRef;

  @ViewChild('cvvField', null)
  cvvField: ElementRef;

  @ViewChild('upiDropdown', {static:false, read: NgbDropdown}) upiDropdown: NgbDropdown;



  fieldNames = fieldnames;
  cardAValue = '';
  cardBValue = '';
  cardCValue = '';
  cardDValue = '';
  monthFieldValue = '';
  yearFieldValue = '';
  cvvFieldValue = '';
  isCollapsed = true;
  currentScreen = '';
  cardNumber = '';
  cardValid = true;
  vpaImage = '';
  cardLogo = '';
  cardNumberError = '';
  nameOnCard = '';
  nameValid = true;
  expDateValid = true;
  cvvHelpToolTip = false;
  cvvValid = true;
  addVpaCheck: any;
  regexSpecial = /^[e.+-/*]$/;
  regexNum = /^\d$/;
  regexChars = '^[a-zA-Z ]*$';
  upiId = '';

  vpaList: Array<{ uid?: string, upiId?: string, customerBankingName?: string, enabled: boolean, logo: string }> = [];
  maskedVpas: any[] = [];
  selectedVPA = -1;
  savedCardCVV = '';
  access_code: any;
  lboxAdditionalDetails: IAdditionalDetails;
  lightBoxData: ILightBoxData;
  isSaveVPAExist = false;
  additionalData: string;
  cardExpiry: any;
  saveCard = 'off';
  cvvCreditSavecard: any;
  token: any;
  preauthShortMsg = '';
  preAuthData = '';
  fallback: string = 'N';
  loyaltyFlag: string = fieldnames.YES;

  otp: any;
  otpVerified = false;
  otpSent = false;
  resentOtp = false;
  exhausted = false;
  invalid = false;
  cvvHelp = false;
  successMessage: any;
  errorMessage: any;
  otpError = false;
  cardNameCredit: any;
  deleteCard = false;
  saveCardCheckReq = true;
  invoiceID: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  enablecvvdata: number;
  showcvvlist: boolean[];
  mobileno: any;
  showretrypage = false;
  isexpirydatevalid = false;
  cvvStatus = false;
  ExpirydateError = '';
  expiryvalid = true;
  CardNumError = '';
  cardnumvalid = true;
  cardvalid = true;
  CardError = '';
  iscardValidate = true;
  cardlength: any;
  lightboxEnable: boolean;
  resent = false;
  regexps: any = {};
  translatedMsgs: any;
  saveVPAOption = true;
  errorAlert = false;
  errorAlertMessage = '';
  previousScreen: string;
  bankIds: Array<{ bankId: string, bankName: string, cardType: string }> = [];
  selectedBank = '';
  selectedBankId: string;
  planList: Array<{
    emiPerMonth: string,
    interestRate: string,
    processingFees: string,
    processingFeeIndicator: 'P' | 'A',
    tenure: string,
    totalAmount: string
  }> = [];
  currency: string;
  fetchedBankId: string;
  emiPayload: { plan: EMIPlanBean; type: string; } = null;
  PageTypes = PageTypes;
  paymentInfo: IPaymentInfo;
  UPIScreens = UPIScreens;
  hierarchyData: IHierarchyData;
  sessionData: ISessionData;
  splitTxnInfo: ISplitTxnData = null;
  splitTxnStatus = false;
  txnData: ITxnData = {} as ITxnData;
  txnStatus: boolean = false;
  retryScreen = '';
  someInputField: any;
  otpTransStatus: any;
  isFinalMessage: boolean = false;
  resmessage: any;
  responseCode: string;
  paymentResponseData: any = [];
  transactionResponseDataVal: any = [];
  transactionResponseDataValfull: any;
  urlCheckConnection: any;
  payMode: any;
  showPaymentOption: boolean;
  transactionId: string;
  childWindow: Window;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  initiateContextPath: string;
  referenceOrderId: any;
  referenceStatus: boolean;
  upiRole: any;
  showValidateUPIError: boolean;
  upiErrorMsg: string;
  paymentInstrumentType: string
  payerAccountName: string
  isFallback = false;
  upiHandles: any = [];
  selectedHandle: string;
  hideDropdown: boolean = true;
  filteredHandles: any;
  skipOrderIdCheckFlag: boolean;

  constructor(
    private cnpdataService: CnpdataService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
    private lightboxdataService: LightboxdataService,
    private paymentRoles: PaymentRolesService,
    private translate: TranslateService,
    private emiPaymentsService: EmiPaymentsService,
    private _route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private paymentRole: PaymentRolesService
  ) {
    this.headerService.getHeaderCurrency().subscribe(resp => this.currency = resp);
    this.headerService.getSplitTxnStatus().subscribe(resp => this.splitTxnStatus = resp);
    this.translate.get([
      'checkoutModule.cardnuminvalid',
      'checkoutModule.cardnotsupported',
      'checkoutModule.emiCardNA'
    ]).subscribe(res => this.translatedMsgs = res);
    const roles = this.paymentRoles.getRoles();
    Object.keys(roles).forEach(role => {
      if (roles[role].paymentInstrumentType === 'UPI') {
        this.upiRole = roles[role];
      }
    });
    if(this.upiRole) {
      this.initiateContextPath = this.upiRole.initiatePaymentContextPath;
      this.paymentInstrumentType = this.upiRole.paymentInstrumentType;
    }
    this.showPaymentOption = false;
  }

  public windowReference: any;
  encRequest: any;
  actionUrl: any;
  newSimulatorUrl: any;

  closeAlert(): void {
    this.errorAlert = false;
  }

  openAlert(message: string): void {
    this.errorAlert = true;
    this.errorAlertMessage = message;
  }

  dataInit(): void {
    this.getVPAList();
    this.getPreAuthMsg();
  }

  initLbox(): void {
    this.lightBoxData = this.userdataService.getLightboxInfo();
    this.lboxAdditionalDetails = this.userdataService.getAdditionalData();
    if (this.lightBoxData.recurringFlag === 'Y' ||
      this.lightBoxData.transactionType === fieldnames.saveCard) {
      this.saveVPAOption = false;
    }
    this.additionalDetails = this.userdataService.getAdditionalData();
    this.dataInit();
  }

  initInv(): void {
    this.lightBoxData = {} as ILightBoxData;
    this.lightBoxData.transactionType = this.PageTypes.INV;
    this.paymentInfo = this.userdataService.getPaymentInfo();
    this.hierarchyData = this.userdataService.getHierarchyData();
    this.additionalDetails = this.userdataService.getAdditionalData();
    // this.saveVPAOption = false;
    this.dataInit();
  }

  ngOnInit() {
    this.sessionData = this.userdataService.getSessionData();
    this.lightboxEnable = this.headerService.getPageType() === PageTypes.LB;
    if (this.lightboxEnable) {
      this.initLbox();
    } else {
      this.initInv();
    }
  }

  goBack(): void {
    this.clearFields();
    if (this.maskedVpas.length) {
      this.currentScreen = this.UPIScreens.SAVEDUPIS;
    } else {
      this.gotoHome();
    }
  }

  clearFields(): void {
    this.upiForm.reset();
    this.cardLogo = '';
    this.vpaImage = '';
    this.CardNumError = '';
    this.expDateValid = true;
    this.cvvValid = true;
    this.upiErrorMsg = ''
  }

  gotoHome(clear?: boolean): void {
    this.showHome.emit(clear);
  }

  private setPaymentMode;

  clearCVV(): void {
    this.cvvFieldValue = '';
    this.savedCardCVV = '';
  }

  goTo(screen: string, clearFactor?: boolean): void {
    if (clearFactor) {
      this.clearCVV();
    }
    this.previousScreen = this.currentScreen;
    this.currentScreen = screen;
    this.selectedHandle = undefined
    this.filteredHandles = this.upiHandles
    this.hideDropdown = true
  }

  retry(changePayload: boolean): void {
    this.fallback = 'N';
    if (this.splitTxnInfo != undefined || this.splitTxnInfo != null) {
      if (this.splitTxnInfo.remainingAmount != undefined || this.splitTxnInfo.remainingAmount != null) {
        this.loyaltyFlag = this.fieldNames.YES;
      }
    }

    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
    }
    if (this.setPaymentMode != 'OnlyForEMIs') {
      this.clearCVV();
    }
    if (changePayload) {
      this.gotoHome(true);
    }
    if (this.cardTypeFromGlobalScope === 'saved') {
      this.currentScreen = this.UPIScreens.SAVEDUPIS;
      this.clearCVV();
    } else if (this.cardTypeFromGlobalScope === 'new') {
    this.currentScreen = this.UPIScreens.HOME;
    } else {
      this.currentScreen = this.UPIScreens.HOME;
  }

    this.headerService.changeHeaderStatus(true);
    this.skipOrderIdCheckFlag = true;
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.UPIScreens.COMPLETE;
    this.loadingFalse();
  }

  private cardTypeFromGlobalScope: any;

  validateText(event) {
    if(this.showPaymentOption) {
      this.showPaymentOption = false
      this.payerAccountName = null
    }

    var charAt = event.key
    if(this.upiId && this.upiId.includes('@') && charAt == '@') {
      return false
    }
    return true
  }

  onUpiEnter(inputValue: string): void {  
    if(this.showPaymentOption) {
      this.showPaymentOption = false
      this.payerAccountName = null
    }
    if(inputValue.includes('@')) {
      this.selectedHandle = undefined
      const handleTextArr = inputValue.split('@');
      const handleText = handleTextArr.length> 1 ?  handleTextArr[1] : ""
      if(handleText == "") {
        this.filteredHandles = this.upiHandles
      } else {
        this.filteredHandles = this.upiHandles.filter(name => name.includes(handleText))
      }
    } else {
      this.upiDropdown.open()
    }
    if(inputValue.endsWith('@')) {
      this.hideDropdown = false
    } else if(inputValue.includes('@') && !inputValue.endsWith('@')) { 
      this.hideDropdown = false
    } else {
      this.hideDropdown = true
    }
    if(inputValue == "") {
      this.filteredHandles = this.upiHandles
    }
  }

  onHandleSelect(handle: string) {
    if(this.upiId.length > 1) {
      if(this.upiId.includes('@') && !this.upiId.endsWith('@')) {
        const handleText = this.upiId.split('@')[1];
        this.upiId = this.upiId.replace(handleText, handle)
        this.selectedHandle = handle
      }
      else {
        this.selectedHandle = handle
        var atPrefix = this.upiId.endsWith('@') ? '' : '@'
        if(!this.upiId.includes('@') || this.upiId.endsWith('@')) {
          this.upiId = this.upiId + atPrefix + this.selectedHandle
        }
      } 
    }
    this.hideDropdown = true
  }

  validateUPI() {
    this.showValidateUPIError = false;
    this.selectedHandle = undefined
    this.filteredHandles = this.upiHandles
    this.hideDropdown = true
    this.loadingTrue();
    if (this.lightboxEnable) {

      let upiPayload: IValidateUPIPayload = {
        hierLabelId: this.lightBoxData.hierLabelIdReference,
        upiId : this.upiId,
        method : this.paymentInstrumentType
    }

      this.cnpdataService.validateUPI(upiPayload).subscribe(resp => {
        const { responseCode = null, isVPAValid = "0", payerAccountName = "" } = resp || {};
        if (responseCode === ResponseCode.successResponseCode && isVPAValid === "1") {
          this.showPaymentOption = true;
          this.payerAccountName = payerAccountName;
        }
        else if (responseCode === ResponseCode.successResponseCode && isVPAValid !== "1") {
          this.handleValidateUPIError("invalid");
        }
        else {
          this.handleValidateUPIError(null);
        }
        this.loadingFalse();
      }, error => {
        this.handleValidateUPIError(null);
      });
    } else {
      let upiPayload: IValidateUPIPayload = {
        hierLabelId: this.hierarchyData.hierLabelIdReference,
        upiId : this.upiId,
        method : this.paymentInstrumentType
    }

    this.cnpdataService.validateUPI(upiPayload).subscribe(resp => {
      const { responseCode = null, isVPAValid = "0", payerAccountName = "" } = resp || {};
      if (responseCode === ResponseCode.successResponseCode && isVPAValid === "1") {
        this.showPaymentOption = true;
        this.payerAccountName = payerAccountName;
      }
      else if (responseCode === ResponseCode.successResponseCode && isVPAValid !== "1") {
        this.handleValidateUPIError("invalid");
      }
      else {
        this.handleValidateUPIError(null);
      }
      this.loadingFalse();
    }, error => {
      this.handleValidateUPIError(null);
    });
    }
  }
  handleValidateUPIError(statusCode: string) {
    this.showValidateUPIError = true;
    if (statusCode === "invalid") {
      this.upiErrorMsg = "UPI ID is incorrect";
    } else {
      this.upiErrorMsg = "UPI validation failed";
    }
    this.loadingFalse();
  }

  generateCheckSum(orderId, amount) {
    let checksumInputString = "";
    checksumInputString = checksumInputString.concat(amount).concat(orderId).concat("1").concat("D").concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputString).digest('hex');
  }

  proceedToPay(cardType: string) {
    this.cardTypeFromGlobalScope = cardType;
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }
    this.retryScreen = this.currentScreen;
    this.initiatePayment('N');
  }

  initiatePayment(fallback) {
    this.transactionId = '';
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }

    this.isFallback = fallback === "Y";

    let height = 500;
    let width = screen.width*0.6;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;
         
    // if (!this.isFallback) {
    //   this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
    //   this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
    // }

    const domainName = this.extractDomainFromUrl(environment.api_url);
    const primaryWebhookURL = `https://${domainName}/service-integrator/txnstatus/webhook`;
    const fallbackWebhookURL = `https://${domainName}/service-integrator/txnstatus/fallback/webhook`;

    const { customerFirstName = "", customerLastName = "" } = this.additionalDetails || {};
    if (this.lightboxEnable) {

      let upiPayload: IUPIPayload = {
        paymentChannelId: this.lightBoxData.paymentChannelId,
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
        consumerMsisdn: this.lightBoxData.mobile,
        amount: this.lightBoxData.transactionAmount,
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.lightBoxData.currency,
        timezone: "India Standard Time",
        emailAddress: this.lightBoxData.email,
        applicationLabel: "UPI",
        customerCode: this.lightBoxData.mobile,
        orderId: this.lightBoxData.orderId,
        checkSumPayment: this.lightBoxData.checkSumPayment,
        electronicGoodsIndicator: 'D', 
        additionalDetails: JSON.stringify(this.additionalDetails),
        customerFirstName,
        customerLastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL : "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      if(this.cardTypeFromGlobalScope === 'saved') {
        upiPayload.tokenId = this.maskedVpas[this.selectedVPA].uid
        upiPayload.customerBankingName = this.maskedVpas[this.selectedVPA].customerBankingName
      } else {
        upiPayload.upiId = this.upiId;
        upiPayload.customerBankingName = this.payerAccountName;
        upiPayload.saveVpa = this.addVpaCheck ? 'true' : 'false';
      }

      this.cnpdataService.initiateUPIPayment(
        this.initiateContextPath, upiPayload, 
        this.splitTxnInfo
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        if (response.referenceOrderId) {
          this.referenceOrderId = response.referenceOrderId;
        }
        if (response.transactionId) {
          this.transactionId = response.transactionId;
        }
        if (response.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }

        if (!this.transactionId || this.txnStatus == false)  {
          this.childWindow && this.childWindow.close();
          this.handleError();
        } else {
          this.referenceStatus = true;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadUpiResponseHTML(response.callBackUrl);
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error)
      });
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      const checkSumPayment = this.generateCheckSum(this.paymentInfo.orderId, this.paymentInfo.amount.toString());

      let upiPayload: IUPIPayload = {
        paymentChannelId: this.hierarchyData.paymentChannelId,
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        hierarchyCriteria: this.hierarchyData.hierarchyCriteria,
        consumerMsisdn: this.paymentInfo.mobileNumber,
        amount: this.paymentInfo.amount.toString(),
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.paymentInfo.currencyCode,
        timezone: "India Standard Time",
        emailAddress: this.paymentInfo.mailId,
        customerCode: this.paymentInfo.mobileNumber,
        applicationLabel: "UPI",
        orderId: this.paymentInfo.orderId,
        checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: this.additionalData,
        customerFirstName: this.paymentInfo.firstName,
        customerLastName: this.paymentInfo.lastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL : "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      if(this.cardTypeFromGlobalScope === 'saved') {
        upiPayload.tokenId = this.maskedVpas[this.selectedVPA].uid
        upiPayload.customerBankingName = this.maskedVpas[this.selectedVPA].customerBankingName
      } else {
        upiPayload.upiId = this.upiId;
        upiPayload.customerBankingName = this.payerAccountName;
        upiPayload.saveVpa = this.addVpaCheck ? 'true' : 'false';
      }

      this.cnpdataService.initiateUPIPayment(
        this.initiateContextPath, upiPayload, null
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        this.referenceOrderId = response.referenceOrderId;
        if (response.transactionId && response.responseCode === ResponseCode.successResponseCode) {
          this.referenceStatus = true;
          this.transactionId = response.transactionId;
          this.txnData.transactionId = this.transactionId;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadUpiResponseHTML(response.callBackUrl);
        } else {
          this.childWindow && this.childWindow.close();
          this.handleError();
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error)
      });
    }
  }

  private extractDomainFromUrl(url: string): string {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return '';
    }
  }

  async loadUpiResponseHTML(htmlResponse) {
    if (this.childWindow) {
      try {
          this.renderHtmlContent(htmlResponse);
      } catch(error) {
        this.retryPaymentWithfallback();
      } 
    }
  }

  renderHtmlContent(htmlResponse) {
    if (htmlResponse) {
      this.childWindow.document.write(htmlResponse);
      let timer = setInterval(() => {
        this.loadingTrue();
        if (this.childWindow.closed) {
          this.txnStatus = false;
          this.handlePolling();
          clearInterval(timer);
          this.childWindow = null;
        }
      }, 50);
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  retryPaymentWithfallback() {
    if(!this.isFallback) {
      this.updateUPITransactionStatus();
      this.initiatePayment('Y');
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  updateUPITransactionStatus() {
    this.lightboxdataService.updateTransStatus(this.transactionId).subscribe(resp => {
    })
  }

  generateCheckSumforTxnStatus(txnId) {
    let checksumInputStringforTxnStatus = "";
    checksumInputStringforTxnStatus = checksumInputStringforTxnStatus.concat(txnId).concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputStringforTxnStatus).digest('hex');
  }

  handlePolling() {
    const checkSumPaymentforTxnStatus = this.generateCheckSumforTxnStatus(this.transactionId);
    this.lightboxdataService.otpTransStatusForWeb(this.transactionId, checkSumPaymentforTxnStatus).subscribe(resp => {
      if (resp) {
        const { ecommPaymentResponseList = [] } = resp;
        const ecommPaymentResponse = ecommPaymentResponseList[0] || {};
        if (ecommPaymentResponse.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }
      }
      if (!resp) {
        this.txnStatus = false;
      }
      if(this.txnStatus && !this.lightboxEnable) {
        this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
          this.showTxnComplete();
        });
      } else {
        this.showTxnComplete();
      }
    },
      err => {
        if (err.status === 0 || err.status === 500 || err.status === 400) {
          this.txnStatus = false;
          this.showTxnComplete();
        }
      });
  }
 
  loadingFalse() {
    this.headerService.changeLoaderStatus(false);
  }
  loadingTrue() {
    this.headerService.changeLoaderStatus(true);
  }

  updateTransactionStatus() {
    let updatetransactionID = this.paymentResponseData[0].transactionId;
    this.lightboxdataService.updateTransStatus(updatetransactionID).subscribe(resp => {
    })
  }

  deleteSavedVPA(): void {
    if (this.selectedVPA > -1 && this.selectedVPA < this.maskedVpas.length) {
      const deletePayload: IDeleteVPAPayload = {
        vpaIds: [this.maskedVpas[this.selectedVPA].uid]
      }
      this.lightboxdataService.deleteSavedVPA(deletePayload).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          if (this.lightboxEnable) {
            this.getVPAList();
          } else {
            this.gotoHome();
          }
        }
      });
    }
  }

  getVPAValidity(cardNumber: string): { type: string, logo: string } {
    const re = /[\s-\s]/gi;
    const cardValue = cardNumber.replace(re, '');
    const typeAndValidty: { type: string, logo: string } = {
      type: '',
      logo: '',
    };

    for (const card in this.regexps) {
      if (cardValue.match(this.regexps[card].regex)) {
        typeAndValidty.type = card;
        typeAndValidty.logo = this.regexps[card].logo;
      }
    }
    return typeAndValidty;
  }

  isFormValid() {
    if (this.upiId) {
      return true;
    }
    return false;
  }

  selectVPA(index: number, enabled: boolean) {
    if (enabled) {
      this.selectedVPA = index;
    } else {
      this.selectedVPA = -1;
    }
    this.savedCardCVV = '';
  }

  isSavedFormValid() {
    return (this.selectedVPA >= 0 && this.selectedVPA < this.vpaList.length);
  }

  getVPAList() {
    const fetchVPAPayload : IFetchVPAPayload = {
      customerCode : this.lightboxEnable ? this.lightBoxData.mobile : this.paymentInfo.mobileNumber,
      hierLabelIdReference: this.lightboxEnable ? this.lightBoxData.hierLabelIdReference : this.hierarchyData.hierLabelIdReference,
      hierarchyCriteria: this.lightboxEnable ? this.lightBoxData.hierarchyCriteria : this.hierarchyData.hierarchyCriteria
  }

    this.lightboxdataService.getVPAList(fetchVPAPayload).subscribe(resp => {
      if (resp.status === '200') {
        this.maskedVpas = resp.maskedVpas;
        if (this.maskedVpas && this.maskedVpas.length) {
          this.vpaList = [];
          this.isSaveVPAExist = true;
          for (const upiToken of this.maskedVpas) {
            this.vpaList.push({
              upiId: upiToken.upiId,
              enabled: true,
              logo: upiToken.logo
            });
          }
          this.currentScreen = this.UPIScreens.SAVEDUPIS;
        } else {
          this.isSaveVPAExist = false;
          this.currentScreen = this.UPIScreens.HOME;
        }
        this.upiHandles = resp.upiHandles
        this.filteredHandles = resp.upiHandles
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  getPreAuthMsg() {
    if (this.lightBoxData.transactionType === fieldnames.preAuth) {
      this.lightboxdataService.getPreAuthMessage(this.lightBoxData.hierLabelIdReference).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.preAuthData = resp.preAuthDelayReq.message;
          this.preauthShortMsg = this.preAuthData.substring(0, 38);
        }
      }, error => this.handleError(error));
    }
  }

  handleError(error?: any): void {
    this.txnStatus = false;
    this.showTxnComplete();
  }
}