export const fieldnames = {
  YES: 'Y',
  NetBankPayment : 'NB',
  Wallet : 'WALLET',
  NO: 'N',
  Card: 'CNP_CREDIT',
  EMIC: 'EMIC',
  sale: 'sale',
  saveCard: 'SaveCard',
  preAuth: 'preauth',
  HOME: 'HOME',
  COMPLETE: 'COMPLETE',
  LOYALTY: 'Loyalty Payment',
  UPI: 'UPI',
  QRCode: 'QRCode',
  PayLater: 'PayLater'
};

export const langName = {
  ENGLISH: 'en',
  SPANISH : 'es'
};

export const ngxTranslateCode = [
  {languageCode: 'en_US' , language: 'en', name: 'English'},
  {languageCode: 'es_ES', language: 'es', name: 'Spanish'}
];

export const paymentChannel = {
  TYPEWEB: 'Web portal'
};

export const CardScreens = {
  OTP: 'otp',
  HOME: 'home',
  SAVEDCARDS: 'savedCards',
  ALLPLANS: 'allPlans',
  SELECTPLANS: 'selectPlans',
  COMPLETE: 'complete'
};

export const BankScreens = {
  HOME: 'home',
  DETAILS: 'details',
  COMPLETE: 'complete'
};

export const WalletScreens = {
  HOME: 'home',
  MSISDN: 'msisdin',
  INSTRUCTIONS: 'instructions',
  COMPLETE: 'complete'
};

export const UPIScreens = {
  OTP: 'otp',
  HOME: 'home',
  SAVEDUPIS: 'savedUPIs',
  ALLPLANS: 'allPlans',
  SELECTPLANS: 'selectPlans',
  COMPLETE: 'complete'
};

export const QRCodeScreens = {
  HOME: 'home',
  COMPLETE: 'complete'
};

export const SimplScreen = {
  COMPLETE: 'complete'
}
