import { Component, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import shajs from 'sha.js';
import { environment } from 'src/environments/environment';

import {
  ResponseCode, ILightBoxData, IAdditionalDetails, IPaymentInfo, IHierarchyData,
  ISessionData, CnpdataService, HeaderService, PageTypes, LightboxdataService,
  UserdataService, ISplitTxnData,
  utcTimeZone, BankScreens, PaymentRolesService, ITxnData, INetbankingPayload, ResponseContextPath
} from 'src/app/core';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BankComponent implements OnInit {
  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();

  bankList: any[] = [];
  bankInstruction: string;
  bankContent: Array<any> = [];
  specialInstruction: string;
  details: any[] = [];
  subDetails: any[] = [];
  selectval: string;
  dropcontent = false;
  transactionId = '';
  referenceOrderId = '';
  bankObj: any;
  additionalData: any;
  invoiceID: string;
  submitButton = false;
  initiatePaymentButton = true;
  selectedBank = '';
  selectedLogo = '';
  bankCode = '';
  showretrypage = false;
  banklistStatus = false;
  lightboxEnable: boolean;
  currentScreen = BankScreens.HOME;

  banks: any = {};

  paymentInfo: IPaymentInfo;
  hierarchyData: IHierarchyData;
  sessionData: ISessionData = {} as ISessionData;
  lightBoxData: ILightBoxData = {} as ILightBoxData;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  PageTypes = PageTypes;
  BankScreens = BankScreens;

  txnStatus = false;
  txnData: ITxnData = {} as ITxnData;

  splitTxnInfo: ISplitTxnData = null;
  splitTxnStatus = false;
  contextPathExists = false;
  childWindow: Window;
  paymentInstrumentType: string;
  initiateContextPath: string;
  referenceStatus: boolean;
  preferredBanks: any[];
  currentBank = 0;
  preferredBanksToDisplay = [];
  selectedOption: string;
  isFallback = false;
  skipOrderIdCheckFlag: boolean;

  constructor(
    private cnpdataService: CnpdataService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
    private router: Router,
    private lightboxdataService: LightboxdataService,
    private paymentRoles: PaymentRolesService
  ) {
    this.sessionData = this.userdataService.getSessionData();
    this.headerService.getSplitTxnStatus().subscribe(resp => this.splitTxnStatus = resp);
    this.lightboxEnable = this.headerService.getPageType() === this.PageTypes.LB;
    this.fetchRoles();
    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
      this.additionalDetails = this.userdataService.getAdditionalData();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
      this.hierarchyData = this.userdataService.getHierarchyData();
    }
    this.getBankDetails();
  }

  fetchRoles(): void {
    const roles = this.paymentRoles.getRoles();
    Object.keys(roles).forEach(role => {
      if (roles[role].paymentInstrumentType === 'BANK') {
        this.banks[role] = roles[role];
      }
    });

  }

  retry(changePayload: boolean): void {
    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
    }
    if (changePayload) {
      this.showHome.emit(changePayload);
    } else {
      this.getBankDetails();
      this.currentScreen = this.BankScreens.HOME;
    }
    this.headerService.changeHeaderStatus(true);
    this.skipOrderIdCheckFlag = true;
  }

  getBankDetails() {
    if (this.checkInitiateContextPath()) {
      return;
    }
    this.txnStatus = false;
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }
    if (this.lightboxEnable) {
      this.additionalData = JSON.stringify(this.additionalData);
      this.lightboxdataService.createTokenAndFetchBankList(
        this.lightBoxData.paymentChannelId,
        this.lightBoxData.hierLabelIdReference,
        this.lightBoxData.hierarchyCriteria,
        this.lightBoxData.transactionAmount, this.lightBoxData.taxAmount,
        this.lightBoxData.currency, utcTimeZone(), 'NetBanking',
        this.lightBoxData.mobile, this.lightBoxData.email,
        this.lightBoxData.hierLabelIdReference, this.lightBoxData.taxIncluded,
        this.lightBoxData.orderId, this.lightBoxData.checkSumPayment,
        this.lightBoxData.electronicGoodsIndicator, this.splitTxnInfo
      ).subscribe(respData => {
        if (respData.responseCode === ResponseCode.successResponseCode) {
          this.paymentRoles.setBankList(respData);
          this.bankList = respData.bankList;
          this.banklistStatus = true;
          this.transactionId = respData.transactionId;
          this.referenceOrderId = respData.referenceOrderId;
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    } else {
      const additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      this.cnpdataService.createTokenAndFetchBankList(
        this.hierarchyData.paymentChannelId,
        this.hierarchyData.hierLabelIdReference,
        this.hierarchyData.hierarchyCriteria,
        this.paymentInfo.amount.toString(), '1', this.paymentInfo.currencyCode,
        utcTimeZone(), 'NetBanking', this.paymentInfo.mobileNumber,
        this.paymentInfo.mailId, this.hierarchyData.hierLabelId, '2', this.paymentInfo.orderId,
        'fd63ab7f20d1176e6c996046a5322aed66405321a9b0263084d7f40dcdb5f55b',
        'D', additionalData
      ).subscribe(respData => {
        if (respData.responseCode === ResponseCode.successResponseCode) {
          this.paymentRoles.setBankList(respData);
          this.bankList = respData.bankList;
          this.banklistStatus = true;
          this.transactionId = respData.transactionId;
          this.referenceOrderId = respData.referenceOrderId;
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    }
  }

  checkInitiateContextPath() {
    let bankNames = Object.keys(this.banks) || [];
    const preferredBankList = [];
    const updatedList = [];
    bankNames = bankNames.sort();
    bankNames.forEach(name => {
      if (this.banks[name].initiatePaymentContextPath !== ResponseContextPath.initiatePayment) {
        this.contextPathExists = false;
        return;
      }
      if (this.banks[name].preferred) {
        preferredBankList.push({
          ...this.banks[name],
          bankName: name
        })
      }
      updatedList.push({
        ...this.banks[name],
        bankName: name
      })
    })

    if (updatedList.length > 0) {
      this.currentBank = 0;
      this.contextPathExists = true;
      this.banklistStatus = true;
      this.bankList = updatedList;
      this.preferredBanks = preferredBankList;
      this.preferredBanksToDisplay = [];
      this.doNext();
    }
    return this.contextPathExists;
  }

  onBankSelect(bank: any): void {
    this.selectedBank = bank.bankName;
    this.bankCode = bank.bankCode;
    this.selectedLogo = this.getBankLogoPath(bank.logo);
    this.paymentInstrumentType = bank.paymentInstrumentType;
    this.initiateContextPath = bank.initiatePaymentContextPath;
  }

  getBankLogoPath(logoUrl: string): string {
    const url = new URL(logoUrl);
    const pathname = url.pathname;
    const filenameMatch = pathname.match(/\/([^\/?]+)$/);
    const filename = filenameMatch ? filenameMatch[1] : null;
    const cleanedFilename = filename ? filename.replace(/^paymentInstrument_/i, '').replace(/\.action$/i, '') : null;
    const logoPath = cleanedFilename ? `assets/images/${cleanedFilename}.png` : null;
    return logoPath;
  }

  setRadio(bankName: string): void {
    this.selectedBank = bankName;
    const currentBank = this.banks[bankName];
    this.selectedLogo = this.getBankLogoPath(currentBank.logo);
    this.initiateContextPath = currentBank.initiatePaymentContextPath;
    this.paymentInstrumentType = currentBank.paymentInstrumentType;
  }


  doNext() {
    for (this.currentBank = 0; this.currentBank < this.preferredBanks.length; this.currentBank++) {
      this.preferredBanksToDisplay.push(this.preferredBanks[this.currentBank]);
    }
  }

  getBankInstruction(): void {
    const bankObj = this.bankList.find(el => el.bankCode === this.bankCode);
    if (!bankObj) {
      return;
    }
    this.bankContent = [];
    this.bankInstruction = bankObj.bankInst;
    this.specialInstruction = bankObj.specialInst;
    this.details = this.bankInstruction.split(';');
    const bankSet: Map<string, string> = new Map<string, string>();
    for (let i = 1; i < this.details.length - 2; i++) {
      const lhash = { key: `${this.details[i].split(':')[0]}`, val: `${this.details[i].split(':')[1]}` };
      bankSet.set(lhash.key, lhash.val);
    }
    for (const [key, val] of bankSet.entries()) {
      const lhash: { key: string, val: string } = {
        key, val
      };
      this.bankContent.push(lhash);
    }
  }

  onSubmit(): void {
    if (this.selectedBank !== null && this.selectedBank.length !== 0) {
      if (this.contextPathExists) {
        this.initiateNetbankingPayment('N');
        return;
      }
      this.getBankInstruction();
      this.goTo(this.BankScreens.DETAILS);
    } else {
      return;
    }
  }

  gotoHome(clear?: boolean): void {
    if (this.lightboxEnable) {
      this.paymentRoles.getRetryOrderId(
        this.lightBoxData.callbackurl, this.lightBoxData.orderId,
        this.lightBoxData.transactionType, this.lightBoxData.transactionAmount
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.lightBoxData.orderId = resp.orderId;
          this.lightBoxData.checkSumPayment = resp.checksumpayment;
          this.userdataService.setLightboxInfo(this.lightBoxData);
          this.showHome.emit(clear);
        } else {
          this.handleError();
        }
      }, error => {
        console.error(error);
        this.router.navigate(['failure']);
      });
    } else {
      this.cnpdataService.getPaymentDatabtBatchId(this.paymentInfo.hashid).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.paymentInfo.orderId = resp.jsonBody.orderId;
          this.userdataService.setPaymentInfo(this.paymentInfo);
          this.showHome.emit(clear);
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    }
  }

  goTo(screen: string): void {
    this.currentScreen = screen;
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.BankScreens.COMPLETE;
    this.loadingFalse()
  }

  initiatePayment() {
    if (this.lightboxEnable) {
      this.lightboxdataService.initiateBankPayment(this.transactionId, this.bankCode,
        this.selectedBank).subscribe(resp => {
          this.txnData.responseCode = resp.responseCode;
          this.txnData.responseMessage = resp.responseMessage;
          this.txnData.referenceOrderId = this.referenceOrderId;
          this.txnData.recurrentToken = resp.recurrentToken;
          if (resp.responseCode === ResponseCode.successResponseCode) {
            this.txnStatus = true;
          } else {
            this.txnStatus = false;
          }
          this.showTxnComplete();
        }, error => {
          console.error(error);
          this.txnStatus = false;
          this.showTxnComplete();
        });
    } else {
      this.cnpdataService.initiateBankPayment(this.transactionId, this.bankCode,
        this.selectedBank).subscribe(resp => {
          this.txnData.responseCode = resp.responseCode;
          this.txnData.responseMessage = resp.responseMessage;
          this.txnData.referenceOrderId = this.referenceOrderId;
          this.txnData.recurrentToken = resp.recurrentToken;
          if (resp.responseCode === ResponseCode.successResponseCode) {
            this.txnStatus = true;
          } else {
            this.txnStatus = false;
          }
          this.showTxnComplete();
        }, error => {
          console.error(error);
          this.txnStatus = false;
          this.showTxnComplete();
        }
        );
    }
  }

  async loadNetbankingResponseHTML(htmlResponse) {
    if (this.childWindow) {
      try {
          this.renderHtmlContent(htmlResponse);
      } catch (error) {
        this.retryPaymentWithfallback();
      }
    }
  }

  renderHtmlContent(htmlResponse) {
    if (htmlResponse) {
      this.childWindow.document.write(htmlResponse);
      let timer = setInterval(() => {
        this.loadingTrue();
        if (this.childWindow.closed) {
          this.txnStatus = false;
          this.handlePolling();
          clearInterval(timer);
          this.childWindow = null;
        }
      }, 50);
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  retryPaymentWithfallback() {
    if (!this.isFallback) {
      this.updateTransactionStatus();
      this.initiateNetbankingPayment('Y');
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  updateTransactionStatus() {
    this.lightboxdataService.updateTransStatus(this.transactionId).subscribe(resp => {
    })
  }


  handlePolling() {
    const checkSumPaymentforTxnStatus = this.generateCheckSumforTxnStatus(this.transactionId);
    this.lightboxdataService.otpTransStatusForWeb(this.transactionId, checkSumPaymentforTxnStatus).subscribe(resp => {
      if (resp) {
        const { ecommPaymentResponseList = [] } = resp;
        const ecommPaymentResponse = ecommPaymentResponseList[0] || {};
        if (ecommPaymentResponse.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }
      }
      if (!resp) {
        this.txnStatus = false;
      }
      if (this.txnStatus && !this.lightboxEnable) {
        this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
          this.showTxnComplete();
        });
      } else {
        this.showTxnComplete();
      }
    },
      err => {
        if (err.status === 0 || err.status === 500 || err.status === 400) {
          this.txnStatus = false;
          this.showTxnComplete();
        }
      });
  }

  loadingTrue() {
    this.headerService.changeLoaderStatus(true);
  }

  loadingFalse() {
    this.headerService.changeLoaderStatus(false);
  }

  generateCheckSumforTxnStatus(txnId) {
    let checksumInputStringforTxnStatus = "";
    checksumInputStringforTxnStatus = checksumInputStringforTxnStatus.concat(txnId).concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputStringforTxnStatus).digest('hex');
  }

  generateCheckSum(orderId, amount) {
    let checksumInputString = "";
    checksumInputString = checksumInputString.concat(amount).concat(orderId).concat("1").concat("D").concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputString).digest('hex');
  }

  initiateNetbankingPayment(fallback) {
    this.transactionId = '';
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }

    this.isFallback = fallback === "Y";

    let height = 500;
    let width = screen.width * 0.6;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;

    // if (!this.isFallback) {
    //   this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
    //   this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
    // }

    const domainName = this.extractDomainFromUrl(environment.api_url);
    const primaryWebhookURL = `https://${domainName}/service-integrator/txnstatus/webhook`;
    const fallbackWebhookURL = `https://${domainName}/service-integrator/txnstatus/fallback/webhook`;

    if (this.lightboxEnable) {
      const { customerFirstName = "", customerLastName = "" } = this.additionalDetails || {};
      let netbankingPayload: INetbankingPayload = {
        paymentChannelId: this.lightBoxData.paymentChannelId,
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        consumerMsisdn: this.lightBoxData.mobile,
        hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
        amount: this.lightBoxData.transactionAmount,
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.lightBoxData.currency,
        timezone: "India Standard Time",
        emailAddress: this.lightBoxData.email,
        applicationLabel: this.selectedBank,
        orderId: this.lightBoxData.orderId,
        checkSumPayment: this.lightBoxData.checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: JSON.stringify(this.additionalDetails),
        customerCode: this.lightBoxData.customerCode,
        customerFirstName,
        customerLastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL : "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };
      this.cnpdataService.initiateNetbankingPayment(
        this.initiateContextPath, netbankingPayload, this.splitTxnInfo
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        if (response.referenceOrderId) {
          this.referenceOrderId = response.referenceOrderId;
        }
        if (response.transactionId) {
          this.transactionId = response.transactionId;
        }
        if (response.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }

        if (!this.transactionId || this.txnStatus == false) {
          this.childWindow && this.childWindow.close();
          this.handleError();
        } else {
          this.referenceStatus = true;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadNetbankingResponseHTML(response.callBackUrl);
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error);
      });
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      const checkSumPayment = this.generateCheckSum(this.paymentInfo.orderId, this.paymentInfo.amount.toString());

      let netbankingPayload: INetbankingPayload = {
        paymentChannelId: this.hierarchyData.paymentChannelId,
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        consumerMsisdn: this.paymentInfo.mobileNumber,
        hierarchyCriteria: this.hierarchyData.hierarchyCriteria,
        amount: this.paymentInfo.amount.toString(),
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.paymentInfo.currencyCode,
        timezone: "India Standard Time",
        emailAddress: this.paymentInfo.mailId,
        applicationLabel: this.selectedBank,
        orderId: this.paymentInfo.orderId,
        checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: this.additionalData,
        customerCode: this.paymentInfo.mobileNumber,
        customerFirstName: this.paymentInfo.firstName,
        customerLastName: this.paymentInfo.lastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL : "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      this.cnpdataService.initiateWalletPayment(
        this.initiateContextPath, netbankingPayload, null
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          console.log(response.redirectURL);
          if (response.redirectURL) {
              window.open(response.redirectURL);
              return;
          }
      }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        this.referenceOrderId = response.referenceOrderId;
        if (response.transactionId && response.responseCode === ResponseCode.successResponseCode) {
          this.referenceStatus = true;
          this.transactionId = response.transactionId;
          this.txnData.transactionId = this.transactionId;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadNetbankingResponseHTML(response.callBackUrl);
        } else {
          this.childWindow && this.childWindow.close();
          this.handleError();
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error);
      });
    }
  }

  private extractDomainFromUrl(url: string): string {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return '';
    }
  }

  navigatebacktopaymantpage() {
    this.router.navigate(['bank']);

  }

  ngOnInit() {
  }

  handleError(error?: any): void {
    if (this.contextPathExists) {
      this.txnStatus = false;
      this.showTxnComplete();
      return;
    }
    if (error) {
      console.error(error);
    }
    this.router.navigate(['error']);
  }
}
