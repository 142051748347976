import { Component, Inject, OnInit, ViewChild, ViewEncapsulation, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { uuid } from 'uuidv4';
import { TranslateService } from '@ngx-translate/core';

import {
  EcomAuthorizationResponse, HeaderService, UserdataService,
  IHierarchyData, CnpdataService, IAdditionalDetails,
  ILightBoxData, ResponseCode, IPaymentInfo, PageTypes, ISessionData,
  EmiPaymentsService, fieldnames, PaymentRolesService,
  LoyaltyService, ISplitTxnData, CssLogoLoader, ITxnData, LightboxdataService
} from 'src/app/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('emailField', null) emailField: ElementRef;

  fieldnames = fieldnames;
  PageTypes = PageTypes;
  mobile: string;
  email: string;
  firstName: string;
  lastName: string;
  prefixSelected: any;
  currencyCode: string;
  mobilPrefixData: any[] = [];
  ecomAuthResponse: EcomAuthorizationResponse;
  uuid: string;
  emailErrorMsg = true;
  emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
  mobilepreStatus = false;
  mobilePreErrorMsg = '';
  mobilErrorcls = false;
  lightBoxData: ILightBoxData = {} as ILightBoxData;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  urlDataInvalid = false;
  urlDataErrorMsg = '';
  isinvalidInstrument: number[] = [];
  translateText: any[] = [];
  paymentChannelIdList: any[] = [];
  pageType: string;
  paymentInfo: IPaymentInfo;
  hierarchyData: IHierarchyData;
  sessionData: ISessionData = {} as ISessionData;
  payMode: string;
  showLoyalty = false;
  loyaltyPoints: number;
  loyaltyWorth: number;
  transactionAmount: number;
  loyaltySplit = false;
  currencySymbol: string;
  netAmount: number;
  splitTxnInfo: ISplitTxnData = {} as ISplitTxnData;
  loyaltyCheckedStatus = false;

  txnData: ITxnData = {} as ITxnData;
  txnStatus = false;
  someInputField: any;
  nameErrorMsg = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private userdataService: UserdataService,
    private router: Router,
    private cnpdataService: CnpdataService,
    private translateService: TranslateService,
    private paymentRoles: PaymentRolesService,
    private emiPaymentsService: EmiPaymentsService,
    private loyaltyService: LoyaltyService,
    private lightBoxService:LightboxdataService
  ) {
    this.payMode = this.fieldnames.HOME;
    this.headerService.getHeaderCurrency().subscribe(resp => this.currencySymbol = resp);
    this.translateService.get(['checkoutModule.mobileprefix',
      'checkoutModule.invalidmobile', 'checkoutModule.mandatoryparameters',
      'checkoutModule.currencyreq', 'checkoutModule.mobilereq', 'checkoutModule.emailreq']).subscribe((resp) => {
        this.translateText = resp;
      });
    this.pageType = this.headerService.getPageType();
    if (this.pageType !== this.PageTypes.INV) {
      this.pageType = this.PageTypes.LB;
      this.headerService.setPageType(this.pageType);
      this.route.queryParams.subscribe(params => {
        if(!this.userdataService.getLightboxInfo()) {
          if (Object.keys(params).length) {
            Object.keys(params).forEach(param => {
              this.lightBoxData[param] = params[param];
            });
          } else {
            this.router.navigate(['notfound']);
          }
        } else {
          this.lightBoxData = this.userdataService.getLightboxInfo();
        }

        if (this.headerService.getRetryUrl()) {
          this.paymentRoles.getRetryOrderId(
            this.lightBoxData.callbackurl, this.lightBoxData.orderId,
            this.lightBoxData.transactionType, this.lightBoxData.transactionAmount
          ).subscribe(resp => {
            if (resp.responseCode === ResponseCode.successResponseCode) {
              this.lightBoxData.orderId = resp.orderId;
              this.lightBoxData.checkSumPayment = resp.checksumpayment;
              localStorage.setItem('lightboxData', JSON.stringify(this.lightBoxData));
              this.userdataService.setLightboxInfo(this.lightBoxData);
            } else {
              this.handleError();
            }
          }, error => {
            console.error(error);
            this.router.navigate(['failure']);
          });
        }
        localStorage.setItem('lightboxData', JSON.stringify(this.lightBoxData));
        this.userdataService.setLightboxInfo(this.lightBoxData);
        this.headerService.setRetryUrl(this.router.url);
      });
      if (this.lightBoxData.additionalDetails) {
        this.additionalDetails = JSON.parse(this.lightBoxData.additionalDetails);
      }
    }
  }

  handleError(error?: any): void {
    if (error) {
      console.error(error);
    }
    this.router.navigate(['error']);
  }

  ismobileValid(): boolean {
    this.mobilePreErrorMsg = '';
    if (!this.prefixSelected) {
      this.mobilePreErrorMsg = this.translateText['checkoutModule.mobileprefix'];
      return false;
    } else if (!this.mobile || this.mobile.length < 4 || this.mobile.length > 15) {
      this.mobilePreErrorMsg = this.translateText['checkoutModule.invalidmobile'];
      return false;
    }
  }

  fetchCurrencyDetails(callback: any = false) {
    let currencyCode = '';
    if (this.pageType === PageTypes.INV) {
      currencyCode = this.paymentInfo.currencyCode;
    } else {
      currencyCode = this.lightBoxData.currency;
    }
    this.cnpdataService.fetchCurrencyDetails(currencyCode).subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        const currencyDetails: any = resp.currencyDetails;
        let curSymbol = currencyDetails.symbol;
        if (curSymbol.indexOf('\\u') !== -1) {
          const indxval = currencyDetails.symbol.substring(2, 6);
          const unicodeInteger = parseInt(indxval, 16);
          curSymbol = String.fromCodePoint(unicodeInteger);
        }
        if (this.pageType === PageTypes.INV) {
          this.headerService.changeHeaderCurrency(curSymbol);
          this.headerService.changeHeaderAmount(this.paymentInfo.amount.toString());
        } else {
          this.headerService.changeHeaderCurrency(curSymbol);
          this.headerService.changeHeaderAmount(this.lightBoxData.transactionAmount);
        }
        if (callback && typeof callback === 'function') {
          callback();
        }
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  getMobilePrefix(callback: any = false) {
    this.cnpdataService.fetchMobilePrefixes().subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        this.mobilPrefixData = resp.countryCodesList;
        if (callback && typeof callback === 'function') {
          callback();
        }
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  authorizeInv() {
    this.cnpdataService.authorize(
      this.hierarchyData.paymentChannelId,
      this.hierarchyData.hierLabelIdReference,
      null,
      this.hierarchyData.hierarchyCriteria
    ).subscribe(resp => {
      if (resp.responseCode === ResponseCode.invaldPaymentchChannelID) {
        this.router.navigate(['failure']);
        return;
      }
      if (resp.responseCode === ResponseCode.successResponseCode) {
        this.paymentRoles.fetchAllMerchantPaymentRoles(
          this.hierarchyData.paymentChannelId,
          this.hierarchyData.hierLabelIdReference,
          this.paymentInfo.currencyCode,
          this.hierarchyData.hierarchyCriteria
        ).subscribe(res => {
          if (res.responseCode === ResponseCode.successResponseCode) {
            if (res.paymentRoles) {
              this.paymentRoles.setRoles(res.paymentRoles);
              this.uuid = uuid();
              this.sessionData.sessionId = this.uuid;
              this.userdataService.setSessionData(this.sessionData);
              if (resp.paymentInstrumentList) {
                this.emiPaymentsService.fetchEmiApplicability(
                  this.hierarchyData.hierLabelIdReference,
                  this.paymentInfo.amount.toString(),
                  this.hierarchyData.paymentChannelId
                ).subscribe(emiResponse => {
                  if (emiResponse.responseCode === ResponseCode.emiEligibility) {
                    this.ecomAuthResponse = resp;
                    if (emiResponse.emiEligibility) {
                      this.ecomAuthResponse.paymentInstrumentList.push({
                        paymentInstrumentId: fieldnames.EMIC,
                        paymentInstrumentName: 'EMI Payment',
                        disabled: false
                      });
                    }
                  } else {
                    this.handleError();
                  }
                  this.headerService.changeHeaderStatus(true);
                  this.headerService.changeFooterStatus(true);
                }, error => this.handleError(error));
              }
            }
          } else {
            this.handleError();
          }
        }, error => this.handleError(error));
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  mandatoryCheck(paramsData: any): boolean {
    const dataKeys = Object.keys(paramsData);
    dataKeys.forEach((key) => {
      if (paramsData[key] == null || paramsData[key] === undefined || paramsData[key] === '') {
        this.urlDataErrorMsg = this.translateText['checkoutModule.mandatoryparameters'];
        this.urlDataInvalid = true;
        return false;
      }
    });
    return true;
  }

  validateData(): boolean {
    if (!this.lightBoxData.currency) {
      this.urlDataInvalid = true;
      this.urlDataErrorMsg = this.translateText['checkoutModule.currencyreq'];
      return false;

    } else if (!this.lightBoxData.mobile) {
      this.urlDataInvalid = true;
      this.urlDataErrorMsg = this.translateText['checkoutModule.mobilereq'];
      return false;

    } else if (!this.lightBoxData.email) {
      this.urlDataInvalid = true;
      this.urlDataErrorMsg = this.translateText['checkoutModule.emailreq'];
      return false;

    } else {
      return true;
    }
  }

  authorizeLbox() {
    this.cnpdataService.authorize(
      this.lightBoxData.paymentChannelId,
      this.lightBoxData.hierLabelIdReference,
      this.lightBoxData.checkSum,
      this.lightBoxData.hierarchyCriteria
    ).subscribe(resp => {
      if (resp.responseCode === ResponseCode.successResponseCode) {
        this.paymentRoles.fetchAllMerchantPaymentRoles(
          this.lightBoxData.paymentChannelId,
          this.lightBoxData.hierLabelIdReference,
          this.lightBoxData.currency,
          this.lightBoxData.hierarchyCriteria
        ).subscribe(res => {
          if (res.responseCode === ResponseCode.successResponseCode) {
            if (res.paymentRoles) {
              this.paymentRoles.setRoles(res.paymentRoles);
              this.uuid = uuid();
              this.sessionData.sessionId = this.uuid;
              this.userdataService.setSessionData(this.sessionData);
              let counter = 0;
              if (fieldnames.YES === this.lightBoxData.recurringFlag) {
                if (resp.paymentInstrumentList) {
                  this.ecomAuthResponse = resp;
                }
                if (fieldnames.YES === this.lightBoxData.freeTrialFlag) {
                  for (const payment of this.ecomAuthResponse.paymentInstrumentList) {
                    if (fieldnames.NetBankPayment === payment.paymentInstrumentId ||
                      fieldnames.Wallet === payment.paymentInstrumentId) {
                      const index = this.ecomAuthResponse.paymentInstrumentList.indexOf(payment);
                      this.isinvalidInstrument[counter++] = index;
                    }
                  }
                } else {
                  for (const payment of this.ecomAuthResponse.paymentInstrumentList) {
                    if (fieldnames.NetBankPayment === payment.paymentInstrumentId ||
                      fieldnames.Wallet === payment.paymentInstrumentId) {
                      const index = this.ecomAuthResponse.paymentInstrumentList.indexOf(payment);
                      this.isinvalidInstrument[counter++] = index;
                    }
                  }
                }
              }
              if (fieldnames.YES === this.lightBoxData.preAuthFlag && fieldnames.
                NO === this.lightBoxData.freeTrialFlag && fieldnames.NO === this.lightBoxData.recurringFlag) {
                if (resp.paymentInstrumentList) {
                  this.ecomAuthResponse = resp;
                }
                for (const payment of this.ecomAuthResponse.paymentInstrumentList) {
                  if (fieldnames.NetBankPayment === payment.paymentInstrumentId ||
                    fieldnames.Wallet === payment.paymentInstrumentId ||
                    fieldnames.LOYALTY === payment.paymentInstrumentId) {
                    const index = this.ecomAuthResponse.paymentInstrumentList.indexOf(payment);
                    this.isinvalidInstrument[counter++] = index;
                  }
                }
              }
              let counter2 = 0;
              for (const invalidindex of this.isinvalidInstrument) {
                this.ecomAuthResponse.paymentInstrumentList.splice(invalidindex - counter2, 1);
                counter2++;
              }
              if (this.lightBoxData.transactionType === fieldnames.saveCard) {
                for (const payment of resp.paymentInstrumentList) {
                  if (payment.paymentInstrumentId === fieldnames.Card) {
                    this.headerService.changeHeaderStatus(true);
                    this.headerService.changeFooterStatus(true);
                    this.payMode = this.fieldnames.Card;
                    return;
                  }
                }
                this.router.navigate(['notfound']);
              }
              if (fieldnames.YES !== this.lightBoxData.recurringFlag
                && fieldnames.YES !== this.lightBoxData.freeTrialFlag
                && fieldnames.YES !== this.lightBoxData.preAuthFlag
              ) {
                this.emiPaymentsService.fetchEmiApplicability(
                  this.lightBoxData.hierLabelIdReference,
                  this.lightBoxData.transactionAmount,
                  this.lightBoxData.paymentChannelId
                ).subscribe(emiResponse => {
                  if (emiResponse.responseCode === ResponseCode.emiEligibility) {
                    if (resp.paymentInstrumentList) {
                      this.ecomAuthResponse = resp;
                      if (emiResponse.emiEligibility) {
                        this.ecomAuthResponse.paymentInstrumentList.push({
                          paymentInstrumentId: fieldnames.EMIC,
                          paymentInstrumentName: 'EMI Payment',
                          disabled: false
                        });
                      }
                    }
                    this.headerService.changeHeaderStatus(true);
                    this.headerService.changeFooterStatus(true);
                    this.getLoyaltyPoints();
                  } else {
                    this.handleError();
                  }
                }, error => this.handleError(error));
              } else {
                this.headerService.changeHeaderStatus(true);
                this.headerService.changeFooterStatus(true);
                this.getLoyaltyPoints();
              }
            }
          } else {
            this.handleError();
          }
        }, error => this.handleError(error));
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  getLoyaltyPoints(): void {
    if (this.lightBoxData.transactionType === fieldnames.preAuth || this.lightBoxData.preAuthFlag === fieldnames.YES) {
      return;
    }

    const index = this.ecomAuthResponse.paymentInstrumentList.findIndex(
      instrument => instrument.paymentInstrumentId === fieldnames.LOYALTY
    );
    if (index === -1) {
      return;
    }

    this.ecomAuthResponse.paymentInstrumentList.splice(index, 1);

    this.loyaltyService.fetchLoyaltyPoints(this.lightBoxData.customerCode, this.lightBoxData.orderId).subscribe(resp => {
      if (resp.responseCode === ResponseCode.loyaltySuccess) {
        this.showLoyalty = true;
        this.loyaltyPoints = Number(resp.jsonBody.points);
        this.loyaltyWorth = Number(resp.jsonBody.amount);
        this.transactionAmount = Number(this.lightBoxData.transactionAmount);
        this.headerService.getHeaderCurrency().subscribe(curr => this.currencyCode = curr);
      }
    }, error => this.handleError(error));
  }

  loadStyle(link: any): void {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = link;
    head.appendChild(style);
  }

  ngAfterViewInit(): void {
    if (this.pageType === this.PageTypes.INV && !this.email) {
      setTimeout(() => this.emailField.nativeElement.focus(), 0);
    }
  }

  ngOnInit(): void {
    if (this.pageType === this.PageTypes.INV) {
      this.paymentInfo = this.userdataService.getPaymentInfo();
      this.hierarchyData = this.userdataService.getHierarchyData();
      if (this.paymentInfo && this.hierarchyData && this.hierarchyData.paymentChannelId) {
        this.mobile = this.paymentInfo.mobileNumber;
        this.prefixSelected = this.paymentInfo.mobilePreFix;
        this.email = this.paymentInfo.mailId;
        this.fetchCurrencyDetails(
          () => this.getMobilePrefix(
            () => this.authorizeInv()
          )
        );
        return;
      } else {
        this.router.navigate(['sessionout']);
      }
    } else {
      this.headerService.changeLoaderStatus(true);
      if (this.validateData() && this.mandatoryCheck(this.lightBoxData)) {
        setTimeout(() => {
          this.headerService.changeLoaderStatus(false);
        }, 3000);
        this.mobile = this.lightBoxData.mobile;
        this.email = this.lightBoxData.email;
        this.prefixSelected = this.lightBoxData.mobilePrefix;
        if (this.lightBoxData.hierLabelIdReference
          && this.lightBoxData.paymentChannelId
          && this.lightBoxData.hierarchyCriteria
        ) {
          this.fetchCurrencyDetails(
            () => this.authorizeLbox()
          );
          localStorage.setItem('lightboxData', JSON.stringify(this.lightBoxData));
          this.userdataService.setLightboxInfo(this.lightBoxData);
          this.userdataService.setAdditionalData(this.additionalDetails);
          if (this.lightBoxData.customCss) {
            this.loadStyle(this.lightBoxData.customCss);
          }
          if (CssLogoLoader.loaderVar.length > 0 && this.lightBoxData) {
            const loaderVal = CssLogoLoader.loaderVar.map(el => this.lightBoxData[el]);
            const dyfilename = loaderVal.join('');
            if (dyfilename) {
              this.loadStyle(`/assets/styles/${dyfilename}.css`);
            }
          }
          return;
        }
      }
      this.router.navigate(['notfound']);
    }
  }

  isEmail(event: KeyboardEvent) {
    const val = this.email;
    if (val.match(this.emailPattern)) {
      this.emailErrorMsg = true;
    } else {
      this.emailErrorMsg = false;
    }
  }

  isName(event: KeyboardEvent) {
    const val = this.firstName;
    this.nameErrorMsg = val && val.length > 0;
  }

  goToPayment(payMode: string, disabled: boolean) {
    if (disabled) {
      return;
    }
    if (this.pageType === this.PageTypes.INV) {
      if (!this.prefixSelected) {
        this.mobilepreStatus = false;
        this.emailErrorMsg = true;
        this.nameErrorMsg = true;
        this.mobilErrorcls = false;
        this.mobilePreErrorMsg = this.translateText['checkoutModule.mobileprefix'];
        return false;
      } else if (!this.mobile || this.mobile.length < 4 || this.mobile.length > 15) {
        this.mobilepreStatus = false;
        this.emailErrorMsg = true;
        this.nameErrorMsg = true;
        this.mobilErrorcls = true;
        this.mobilePreErrorMsg = this.translateText['checkoutModule.invalidmobile'];
        return false;
      } else if (!this.email || !this.email.match(this.emailPattern)) {
        this.emailErrorMsg = false;
        this.mobilepreStatus = true;
        return false;
      } else if (!this.firstName) {
        this.nameErrorMsg = false;
        return false;
      } else {
        this.paymentInfo.mobileNumber = this.mobile;
        this.paymentInfo.mailId = this.email;
        this.paymentInfo.firstName = this.firstName;
        this.paymentInfo.lastName = this.lastName;
        if (typeof (this.prefixSelected) === 'object') {
          this.paymentInfo.mobilePreFix = this.prefixSelected.mobileNumberNetworkPrefix;
        } else {
          this.paymentInfo.mobilePreFix = this.prefixSelected;
        }
        this.userdataService.setPaymentInfo(this.paymentInfo);
      }
    }
    if (this.loyaltySplit) {
      this.splitTxnInfo.points = this.loyaltyPoints;
      this.splitTxnInfo.remainingAmount = this.netAmount;
      this.splitTxnInfo.worthAmount = this.loyaltyWorth;
      this.userdataService.setSplitTxnInfo(this.splitTxnInfo);
      this.headerService.changeSplitTxnStatus(true);
    }
    this.payMode = payMode;
  }

  showHome(clear?: boolean): void {
    if (clear) {
      this.loyaltyCheckedStatus = false;
      this.loyaltySplit = false;
      this.togglePaymentModes(false);
    }
    this.payMode = fieldnames.HOME;
    this.headerService.changeSplitTxnStatus(false);
    this.uuid = uuid();
    this.sessionData.sessionId = this.uuid;
    this.userdataService.setSessionData(this.sessionData);
  }

  togglePaymentModes(disabledFactor: boolean): void {
    this.ecomAuthResponse.paymentInstrumentList.forEach(pmode => {
      pmode.disabled = disabledFactor;
    });
  }

  loyaltyChecked(payload: boolean): void {
    this.loyaltyCheckedStatus = payload;
    if (!payload) {
      this.loyaltySplit = false;
      this.togglePaymentModes(false);
    }
    if (payload && this.loyaltyWorth >= this.transactionAmount) {
      this.togglePaymentModes(true);
    }
    if (payload && this.loyaltyWorth < this.transactionAmount) {
      this.loyaltySplit = true;
      this.ecomAuthResponse.paymentInstrumentList.forEach(pmode => {
        if (pmode.paymentInstrumentId === fieldnames.EMIC) {
          pmode.disabled = true;
        }
      });
      this.netAmount = this.transactionAmount - this.loyaltyWorth;
      this.lightBoxService.setFinalAmount(this.netAmount);
    }
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.payMode = this.fieldnames.COMPLETE;
  }

  retry(payload: boolean) {
    this.headerService.changeHeaderStatus(true);
    this.showHome(payload);
  }

  loyaltyPay(): void {
    this.loyaltyService.loyaltySale(
      this.loyaltyPoints.toString(), this.lightBoxData.transactionAmount,
      this.lightBoxData, this.lightBoxData.additionalDetails
    ).subscribe(resp => {
      if (resp.ecommPaymentResponseList) {
        resp.ecommPaymentResponseList.forEach(element => {
          this.txnData.responseCode = element.responseCode;
          this.txnData.responseMessage = element.responseMessage;
          this.txnData.referenceOrderId = element.referenceOrderId;
          this.txnData.recurrentToken = element.recurrentToken;
          if (element.responseCode === ResponseCode.successResponseCode ||
            element.responseCode === ResponseCode.loyaltySaleSuccess) {
            this.txnStatus = true;
          } else {
            this.txnStatus = false;
          }
        });
      }
      this.showTxnComplete();
    }, err => {
      console.error(err);
      this.txnStatus = false;
      this.showTxnComplete();
    });
  }
}
