import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import shajs from 'sha.js';
import {
  ILightBoxData, IAdditionalDetails, EMIPlanBean, EmiPaymentsService,
  HeaderService, LightboxdataService, UserdataService,
  CnpdataService, fieldnames, PageTypes, IPaymentInfo, QRCodeScreens,
  IHierarchyData, ISessionData, ResponseCode,
  OtpResponseConstants, PaymentRolesService, ISplitTxnData, ITxnData, IUPIPayload, IValidateUPIPayload, IFetchVPAPayload, IDeleteVPAPayload, AuthToken
} from 'src/app/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QRCodeComponent implements OnInit {

  elementType = NgxQrcodeElementTypes.CANVAS;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrValue = "";

  @Input()
  isEmiScreen = false;

  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('upiForm', { static: false })
  upiForm: NgForm;

  @ViewChild('cardA', null)
  cardAField: ElementRef;

  @ViewChild('input1', null)
  otp1Field: ElementRef;

  @ViewChild('cvvField', null)
  cvvField: ElementRef;

  fieldNames = fieldnames;
  cardAValue = '';
  cardBValue = '';
  cardCValue = '';
  cardDValue = '';
  monthFieldValue = '';
  yearFieldValue = '';
  cvvFieldValue = '';
  isCollapsed = true;
  currentScreen = '';
  cardNumber = '';
  cardValid = true;
  vpaImage = '';
  cardLogo = '';
  cardNumberError = '';
  nameOnCard = '';
  nameValid = true;
  expDateValid = true;
  cvvHelpToolTip = false;
  cvvValid = true;
  addVpaCheck: any;
  regexSpecial = /^[e.+-/*]$/;
  regexNum = /^\d$/;
  regexChars = '^[a-zA-Z ]*$';
  upiId = '';

  vpaList: Array<{ uid?: string, upiId?: string, customerBankingName?: string, enabled: boolean }> = [];
  maskedVpas: any[] = [];
  selectedVPA = -1;
  savedCardCVV = '';
  access_code: any;
  lboxAdditionalDetails: IAdditionalDetails;
  lightBoxData: ILightBoxData;
  isSaveVPAExist = false;
  additionalData: string;
  cardExpiry: any;
  saveCard = 'off';
  cvvCreditSavecard: any;
  token: any;
  preauthShortMsg = '';
  preAuthData = '';
  fallback: string = 'N';
  loyaltyFlag: string = fieldnames.YES;

  otp: any;
  otpVerified = false;
  otpSent = false;
  resentOtp = false;
  exhausted = false;
  invalid = false;
  cvvHelp = false;
  successMessage: any;
  errorMessage: any;
  otpError = false;
  cardNameCredit: any;
  deleteCard = false;
  saveCardCheckReq = true;
  invoiceID: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  enablecvvdata: number;
  showcvvlist: boolean[];
  mobileno: any;
  showretrypage = false;
  isexpirydatevalid = false;
  cvvStatus = false;
  ExpirydateError = '';
  expiryvalid = true;
  CardNumError = '';
  cardnumvalid = true;
  cardvalid = true;
  CardError = '';
  iscardValidate = true;
  cardlength: any;
  lightboxEnable: boolean;
  resent = false;
  regexps: any = {};
  translatedMsgs: any;
  saveVPAOption = true;
  errorAlert = false;
  errorAlertMessage = '';
  previousScreen: string;
  bankIds: Array<{ bankId: string, bankName: string, cardType: string }> = [];
  selectedBank = '';
  selectedBankId: string;
  planList: Array<{
    emiPerMonth: string,
    interestRate: string,
    processingFees: string,
    processingFeeIndicator: 'P' | 'A',
    tenure: string,
    totalAmount: string
  }> = [];
  currency: string;
  fetchedBankId: string;
  emiPayload: { plan: EMIPlanBean; type: string; } = null;
  PageTypes = PageTypes;
  paymentInfo: IPaymentInfo;
  QRCodeScreens = QRCodeScreens;
  hierarchyData: IHierarchyData;
  sessionData: ISessionData;
  splitTxnInfo: ISplitTxnData = null;
  splitTxnStatus = false;
  txnData: ITxnData = {} as ITxnData;
  txnStatus: boolean = false;
  retryScreen = '';
  someInputField: any;
  otpTransStatus: any;
  isFinalMessage: boolean = false;
  resmessage: any;
  responseCode: string;
  paymentResponseData: any = [];
  transactionResponseDataVal: any = [];
  transactionResponseDataValfull: any;
  urlCheckConnection: any;
  payMode: any;
  showPaymentOption: boolean;
  transactionId: string;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  initiateContextPath: string;
  referenceOrderId: any;
  referenceStatus: boolean;
  qrcodeRole: any;
  showValidateUPIError: boolean;
  upiErrorMsg: string;
  paymentInstrumentType: string
  paymentInstrument: string
  payerAccountName: string
  isFallback = false;
  qrImage: string;
  timeoutInSeconds: string;
  originalTimeout: number
  timeoutInterval: NodeJS.Timer;
  showRetry: boolean;
  retryCount = 2;
  appToken = new AuthToken();
  retryPayment: any;
  showLoader: boolean;
  pollingTimeout: NodeJS.Timer;
  pollingInterval: NodeJS.Timer;
  showCanvas: any;
  extractQR = false
  qrScale: string;
  skipOrderIdCheckFlag: boolean;

  constructor(
    private cnpdataService: CnpdataService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
    private lightboxdataService: LightboxdataService,
    private paymentRoles: PaymentRolesService,
    private translate: TranslateService,
    private emiPaymentsService: EmiPaymentsService,
    private _route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private paymentRole: PaymentRolesService,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.headerService.getHeaderCurrency().subscribe(resp => this.currency = resp);
    this.headerService.getSplitTxnStatus().subscribe(resp => this.splitTxnStatus = resp);
    this.translate.get([
      'checkoutModule.cardnuminvalid',
      'checkoutModule.cardnotsupported',
      'checkoutModule.emiCardNA'
    ]).subscribe(res => this.translatedMsgs = res);
    const roles = this.paymentRoles.getRoles();
    Object.keys(roles).forEach(role => {
      if (roles[role].paymentInstrumentType === 'QRCode') {
        this.qrcodeRole = roles[role];
        this.paymentInstrument = role;
      }
    });
    if (this.qrcodeRole) {
      this.initiateContextPath = this.qrcodeRole.initiatePaymentContextPath;
      this.paymentInstrumentType = this.qrcodeRole.paymentInstrumentType;
    }
    this.showPaymentOption = false;
    this.appToken = this.userdataService.getTokenData();
  }

  public windowReference: any;
  encRequest: any;
  actionUrl: any;
  newSimulatorUrl: any;

  getQRImage(): string {
    return this.qrImage
  }

  closeAlert(): void {
    this.errorAlert = false;
  }

  openAlert(message: string): void {
    this.errorAlert = true;
    this.errorAlertMessage = message;
  }

  dataInit(): void {
    this.currentScreen = this.QRCodeScreens.HOME;
    this.proceedToPay();
  }

  initLbox(): void {
    this.lightBoxData = this.userdataService.getLightboxInfo();
    this.lboxAdditionalDetails = this.userdataService.getAdditionalData();
    if (this.lightBoxData.recurringFlag === 'Y' ||
      this.lightBoxData.transactionType === fieldnames.saveCard) {
      this.saveVPAOption = false;
    }
    this.additionalDetails = this.userdataService.getAdditionalData();
    this.dataInit();
  }

  initInv(): void {
    this.lightBoxData = {} as ILightBoxData;
    this.lightBoxData.transactionType = this.PageTypes.INV;
    this.paymentInfo = this.userdataService.getPaymentInfo();
    this.hierarchyData = this.userdataService.getHierarchyData();
    this.additionalDetails = this.userdataService.getAdditionalData();
    this.saveVPAOption = false;
    this.dataInit();
  }

  ngOnInit() {
    this.sessionData = this.userdataService.getSessionData();
    this.lightboxEnable = this.headerService.getPageType() === PageTypes.LB;
    if (this.lightboxEnable) {
      this.initLbox();
    } else {
      this.initInv();
    }
  }

  goBack(): void {
    this.clearFields();
    this.clearAllTimers();
    if (this.maskedVpas.length) {

    } else {
      this.gotoHome();
      if (this.retryCount > 0) {
        if (this.lightboxEnable) {
          this.paymentRoles.getRetryOrderId(
            this.lightBoxData.callbackurl, this.lightBoxData.orderId,
            this.lightBoxData.transactionType, this.lightBoxData.transactionAmount
          ).subscribe(resp => {
            if (resp.responseCode === ResponseCode.successResponseCode) {
              this.retryCount = this.retryCount - 1;
              this.appToken.setRetryCount(this.retryCount.toString());
              this.userdataService.setTokenData(this.appToken);
              this.lightBoxData.orderId = resp.orderId;
              this.lightBoxData.checkSumPayment = resp.checksumpayment;
              this.userdataService.setLightboxInfo(this.lightBoxData);
            }
          });
        }
      }
    }
  }

  clearFields(): void {
    this.upiForm.reset();
    this.cardLogo = '';
    this.vpaImage = '';
    this.CardNumError = '';
    this.expDateValid = true;
    this.cvvValid = true;
  }

  gotoHome(clear?: boolean): void {
    this.showHome.emit(clear);
  }

  private setPaymentMode;

  clearCVV(): void {
    this.cvvFieldValue = '';
    this.savedCardCVV = '';
  }

  goTo(screen: string, clearFactor?: boolean): void {
    if (clearFactor) {
      this.clearCVV();
    }
    this.previousScreen = this.currentScreen;
    this.currentScreen = screen;
  }

  retry(changePayload: boolean): void {
    this.fallback = 'N';
    if (this.splitTxnInfo != undefined || this.splitTxnInfo != null) {
      if (this.splitTxnInfo.remainingAmount != undefined || this.splitTxnInfo.remainingAmount != null) {
        this.loyaltyFlag = this.fieldNames.YES;
      }
    }

    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
    }
    if (changePayload) {
      this.gotoHome(true);
    }
    if (this.cardTypeFromGlobalScope === 'saved') {
      this.clearCVV();
    } else if (this.cardTypeFromGlobalScope === 'new') {
      this.currentScreen = this.QRCodeScreens.HOME;
    } else {
      this.currentScreen = this.QRCodeScreens.HOME;
    }

    this.headerService.changeHeaderStatus(true);
    this.skipOrderIdCheckFlag = true;
    this.initiatePayment('N');
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.QRCodeScreens.COMPLETE;
    this.loadingFalse();
  }

  private cardTypeFromGlobalScope: any;

  generateCheckSum(orderId, amount) {
    let checksumInputString = "";
    checksumInputString = checksumInputString.concat(amount).concat(orderId).concat("1").concat("D").concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputString).digest('hex');
  }

  proceedToPay() {
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }
    this.retryScreen = this.currentScreen;
    this.initiatePayment('N');
  }

  initiatePayment(fallback) {
    this.transactionId = '';
    this.showLoader = true;
    this.showRetry = false;
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }

    this.isFallback = fallback === "Y";

    let height = 500;
    let width = screen.width * 0.6;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;

    if (!this.isFallback) {
    }
    const domainName = this.extractDomainFromUrl(environment.api_url);
    const primaryWebhookURL = `https://${domainName}/service-integrator/txnstatus/webhook`;
    const fallbackWebhookURL = `https://${domainName}/service-integrator/txnstatus/fallback/webhook`;
    const { customerFirstName = "", customerLastName = "" } = this.additionalDetails || {};
    if (this.lightboxEnable) {

      let upiPayload: IUPIPayload = {
        paymentChannelId: this.lightBoxData.paymentChannelId,
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
        consumerMsisdn: this.lightBoxData.mobile,
        amount: this.lightBoxData.transactionAmount,
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.lightBoxData.currency,
        timezone: "India Standard Time",
        emailAddress: this.lightBoxData.email,
        applicationLabel: this.paymentInstrument,
        customerCode: this.lightBoxData.customerCode,
        orderId: this.lightBoxData.orderId,
        checkSumPayment: this.lightBoxData.checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: JSON.stringify(this.additionalDetails),
        customerFirstName,
        customerLastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL: "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      this.cnpdataService.initiateUPIPayment(
        this.initiateContextPath, upiPayload,
        this.splitTxnInfo
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length <= 0) {
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        if (response.referenceOrderId) {
          this.referenceOrderId = response.referenceOrderId;
        }
        if (response.transactionId) {
          this.transactionId = response.transactionId;
        }
        if (response.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }

        if (!this.transactionId || this.txnStatus == false) {
          this.handleError();
        } else {
          this.referenceStatus = true;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          if (response.callBackUrl) {
            this.loadUpiResponseHTML(response.callBackUrl, response.qrDetails.timeoutInSeconds);
          } else {
            this.loadUpiResponseHTML(response.qrDetails.qrString, response.qrDetails.timeoutInSeconds, true);
          }
        }
      }, error => {
        this.handleError(error)
      });
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      const checkSumPayment = this.generateCheckSum(this.paymentInfo.orderId, this.paymentInfo.amount.toString());

      let upiPayload: IUPIPayload = {
        paymentChannelId: this.hierarchyData.paymentChannelId,
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        hierarchyCriteria: this.hierarchyData.hierarchyCriteria,
        consumerMsisdn: this.paymentInfo.mobileNumber,
        amount: this.paymentInfo.amount.toString(),
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.paymentInfo.currencyCode,
        timezone: "India Standard Time",
        emailAddress: this.paymentInfo.mailId,
        applicationLabel: this.paymentInstrument,
        orderId: this.paymentInfo.orderId,
        checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: this.additionalData,
        customerFirstName: this.paymentInfo.firstName,
        customerLastName: this.paymentInfo.lastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL: "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      if (this.cardTypeFromGlobalScope === 'saved') {
        upiPayload.tokenId = this.maskedVpas[this.selectedVPA].uid
        upiPayload.customerBankingName = this.maskedVpas[this.selectedVPA].customerBankingName
      } else {
        upiPayload.upiId = this.upiId;
        upiPayload.customerBankingName = this.payerAccountName;
        upiPayload.saveVpa = this.addVpaCheck ? 'true' : 'false';
      }

      this.cnpdataService.initiateUPIPayment(
        this.initiateContextPath, upiPayload, null
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length <= 0) {
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        this.referenceOrderId = response.referenceOrderId;
        if (response.transactionId && response.responseCode === ResponseCode.successResponseCode) {
          this.referenceStatus = true;
          this.transactionId = response.transactionId;
          this.txnData.transactionId = this.transactionId;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          if (response.callBackUrl) {
            this.loadUpiResponseHTML(response.callBackUrl, response.qrDetails.timeoutInSeconds);
          } else {
            this.loadUpiResponseHTML(response.qrDetails.qrString, response.qrDetails.timeoutInSeconds, true);
          }
        } else {
          this.handleError();
        }
      }, error => {
        this.handleError(error)
      });
    }
  }

  private extractDomainFromUrl(url: string): string {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return '';
    }
  }

  async loadUpiResponseHTML(htmlResponse, timeoutVal, renderCanvas = false) {
    try {
        this.renderHtmlContent(htmlResponse, timeoutVal, renderCanvas);
    } catch (error) {
      this.retryPaymentWithfallback();
    }
  }

  renderHtmlContent(htmlResponse, timeoutValue, renderCanvas) {
    if (htmlResponse) {
      this.originalTimeout = timeoutValue;
      this.showCanvas = renderCanvas;

      if (renderCanvas) {
        this.showLoader = false;
        this.qrScale = "3"
        this.qrValue = htmlResponse
        this.setTimeoutInterval()
        this.startPolling()
      } else {
        this.qrImage = htmlResponse
        this.showLoader = false;
        this.setTimeoutInterval()
        this.startPolling()
      }
    } else {
      this.showLoader = false;
      this.handleError();
    }
  }

  onFetchQRContent(result) {
    if (result.content) {
      this.extractQR = false
      this.showCanvas = true
      this.showLoader = false;
      this.qrScale = "3"
      this.qrValue = result.content
      this.setTimeoutInterval()
      this.startPolling()
    } else {
      this.showLoader = false;
      this.txnStatus = false;
      this.handleError();
    }
  }

  updateTimeoutImage(timeoutValue) {
    const minutes = Math.floor(timeoutValue / 60);
    const seconds = timeoutValue - minutes * 60;
    const unit = minutes > 0 ? 'mins' : 'seconds';

    if (minutes <= 0) {
      this.timeoutInSeconds = `The QR will expire in ${seconds} ${unit}`;
    }
    else if (seconds > 0) {
      this.timeoutInSeconds = `The QR will expire in ${minutes}:${seconds} ${unit}`;
    } else {
      this.timeoutInSeconds = `The QR will expire in ${minutes} ${unit}`;
    }
  }

  setTimeoutInterval() {
    let countdown = 0;
    this.timeoutInterval = setInterval(() => {
      const updatedTime = this.originalTimeout - countdown;
      this.updateTimeoutImage(updatedTime);
      if (updatedTime === 0) {
        this.clearAllTimers()
        this.handlePolling(true)
      }
      countdown++
    }, 1000)
  }

  enableRetryOption() {
    this.timeoutInSeconds = `Previous QR Code expired`;
    this.showRetry = true;
    if (this.retryCount <= 0) {
      this.handleError()
    }
  }

  clearAllTimers() {
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
      this.timeoutInterval = null
    }
    if (this.pollingTimeout) {
      clearTimeout(this.pollingTimeout)
      this.pollingTimeout = null
    }
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null
    }
  }

  startPolling() {
    this.pollingTimeout = setTimeout(() => {
      this.handlePolling(false)

      this.pollingInterval = setInterval(() => {
        this.handlePolling(false)
      }, 10000)

      clearTimeout(this.pollingTimeout)
      this.pollingTimeout = null;
    }, 20000)
  }

  retryQR() {
    if (this.retryCount > 0) {
      if (this.lightboxEnable) {
        this.paymentRoles.getRetryOrderId(
          this.lightBoxData.callbackurl, this.lightBoxData.orderId,
          this.lightBoxData.transactionType, this.lightBoxData.transactionAmount
        ).subscribe(resp => {
          if (resp.responseCode === ResponseCode.successResponseCode) {
            this.retryCount = this.retryCount - 1;
            this.appToken.setRetryCount(this.retryCount.toString());
            this.userdataService.setTokenData(this.appToken);
            this.lightBoxData.orderId = resp.orderId;
            this.lightBoxData.checkSumPayment = resp.checksumpayment;
            this.userdataService.setLightboxInfo(this.lightBoxData);
            this.initiatePayment('N');
          }
        });
      } else {
        this.cnpdataService.getPaymentDatabtBatchId(this.paymentInfo.hashid).subscribe(resp => {
          if (resp.responseCode === ResponseCode.successResponseCode) {
            this.retryCount = this.retryCount - 1;
            this.appToken.setRetryCount(this.retryCount.toString());
            this.userdataService.setTokenData(this.appToken);
            this.paymentInfo.orderId = resp.jsonBody.orderId;
            this.userdataService.setPaymentInfo(this.paymentInfo);
            this.initiatePayment('N');
          }
        });
      }
    } else {
      this.handleError();
    }
  }

  retryPaymentWithfallback() {
    if (!this.isFallback) {
      this.updateQRTransactionStatus();
      this.initiatePayment('Y');
    } else {
      this.handleError();
    }
  }

  updateQRTransactionStatus() {
    this.lightboxdataService.updateTransStatus(this.transactionId).subscribe(resp => {
    })
  }

  generateCheckSumforTxnStatus(txnId) {
    let checksumInputStringforTxnStatus = "";
    checksumInputStringforTxnStatus = checksumInputStringforTxnStatus.concat(txnId).concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputStringforTxnStatus).digest('hex');
  }

  handlePolling(isFinalCall) {
    const checkSumPaymentforTxnStatus = this.generateCheckSumforTxnStatus(this.transactionId);
    this.lightboxdataService.otpTransStatusForWeb(this.transactionId, checkSumPaymentforTxnStatus).subscribe(resp => {
      if (resp) {
        const { ecommPaymentResponseList = [] } = resp;
        const ecommPaymentResponse = ecommPaymentResponseList[0] || {};

        if (ecommPaymentResponse.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
          this.clearAllTimers();
          this.updateStatus();
        } else if (ecommPaymentResponse.responseCode === ResponseCode.failureResponseCode) {
          this.txnStatus = false;
          this.clearAllTimers()
          this.updateStatus();
        } else if (ecommPaymentResponse.responseCode === ResponseCode.pendingResponseCode) {
          if (isFinalCall) {
            this.updateQRTransactionStatus();
            this.enableRetryOption()
          }
        } else if (ecommPaymentResponse.responseCode === ResponseCode.expiredResponseCode) {
          this.txnStatus = false;
          this.clearAllTimers()
          this.enableRetryOption()
        } else {
          this.txnStatus = false;
          this.clearAllTimers();
          this.updateStatus();
        }
      } else if (!resp) {
        this.txnStatus = false;
        this.clearAllTimers();
        this.updateStatus();
      }
    },
      err => {
        if (err.status === 0 || err.status === 500 || err.status === 400) {
          this.txnStatus = false;
          this.clearAllTimers();
          this.showTxnComplete();
        }
      });
  }

  updateStatus() {
    if (this.txnStatus && !this.lightboxEnable) {
      this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
        this.showTxnComplete();
      });
    } else {
      this.showTxnComplete();
    }
  }

  loadingFalse() {
    this.headerService.changeLoaderStatus(false);
  }
  loadingTrue() {
    this.headerService.changeLoaderStatus(true);
  }

  updateTransactionStatus() {
    let updatetransactionID = this.paymentResponseData[0].transactionId;
    this.lightboxdataService.updateTransStatus(updatetransactionID).subscribe(resp => {
    })
  }

  deleteSavedVPA(): void {
    if (this.selectedVPA > -1 && this.selectedVPA < this.maskedVpas.length) {
      const deletePayload: IDeleteVPAPayload = {
        vpaIds: [this.maskedVpas[this.selectedVPA].uid]
      }
      this.lightboxdataService.deleteSavedVPA(deletePayload).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          if (this.lightboxEnable) {
            this.getVPAList();
          } else {
            this.gotoHome();
          }
        }
      });
    }
  }

  getVPAValidity(cardNumber: string): { type: string, logo: string } {
    const re = /[\s-\s]/gi;
    const cardValue = cardNumber.replace(re, '');
    const typeAndValidty: { type: string, logo: string } = {
      type: '',
      logo: '',
    };

    for (const card in this.regexps) {
      if (cardValue.match(this.regexps[card].regex)) {
        typeAndValidty.type = card;
        typeAndValidty.logo = this.regexps[card].logo;
      }
    }
    return typeAndValidty;
  }

  isFormValid() {
    if (this.upiId) {
      return true;
    }
    return false;
  }

  selectVPA(index: number, enabled: boolean) {
    if (enabled) {
      this.selectedVPA = index;
    } else {
      this.selectedVPA = -1;
    }
    this.savedCardCVV = '';
  }

  isSavedFormValid() {
    return (this.selectedVPA >= 0 && this.selectedVPA < this.vpaList.length);
  }

  getVPAList() {
    const fetchVPAPayload: IFetchVPAPayload = {
      customerCode: this.lightboxEnable ? this.lightBoxData.customerCode : '',
      hierLabelIdReference: this.lightboxEnable ? this.lightBoxData.hierLabelIdReference : this.hierarchyData.hierLabelIdReference,
      hierarchyCriteria: this.lightboxEnable ? this.lightBoxData.hierarchyCriteria : this.hierarchyData.hierarchyCriteria
    }

    this.lightboxdataService.getVPAList(fetchVPAPayload).subscribe(resp => {
      if (resp.status === '200') {
        this.maskedVpas = resp.maskedVpas;
        if (this.maskedVpas && this.maskedVpas.length) {
          this.vpaList = [];
          this.isSaveVPAExist = true;
          for (const upiToken of this.maskedVpas) {
            this.vpaList.push({
              upiId: upiToken.upiId,
              enabled: true,
            });
          }
        } else {
          this.isSaveVPAExist = false;
          this.currentScreen = this.QRCodeScreens.HOME;
        }
      } else {
        this.handleError();
      }
    }, error => this.handleError(error));
  }

  getPreAuthMsg() {
    if (this.lightBoxData.transactionType === fieldnames.preAuth) {
      this.lightboxdataService.getPreAuthMessage(this.lightBoxData.hierLabelIdReference).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.preAuthData = resp.preAuthDelayReq.message;
          this.preauthShortMsg = this.preAuthData.substring(0, 38);
        }
      }, error => this.handleError(error));
    }
  }

  handleError(error?: any): void {
    // this.showLoader = false;
    this.txnStatus = false;
    this.showTxnComplete();
  }
}