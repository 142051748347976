import { EMIPlanBean } from './../models/paymentInstrument.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { utcTimeZone } from 'src/app/core/utils';
import { fieldnames, IWalletPayload, ISplitTxnData, IPaymentInfo, IHierarchyData, IUPIPayload, IValidateUPIPayload, INetbankingPayload, ICardPayload, ISimpleEligibility } from 'src/app/core/models';

@Injectable()
export class CnpdataService {
  fallback: string;

  constructor(private apiService: ApiService) { }


  getPaymentDatabtBatchId(hashID) {
    return this.apiService.post(environment.api_url + '/invoice/payinfo', hashID)
      .pipe(map(data => data));
  }

  getPaymentDatabByInvId(invID) {
    return this.apiService.post(environment.api_url + '/invoice/paymentgetid', invID)
      .pipe(map(data => data));
  }

  fetchHierarchyDetails(userID: string) {
    return this.apiService.post(environment.api_mpg_url + '/fetchHierarchyDetails', { userId: userID })
      .pipe(map(data => data));
  }

  fetchCurrencyDetails(curCode: string) {
    return this.apiService.post(environment.api_mpg_url + '/fetchCurrencyDetails', { transactionCurrencyCode: curCode })
      .pipe(map(data => data));
  }

  fetchMobilePrefixes() {
    return this.apiService.post(environment.api_mpg_url + '/fetchMobilePrefixes', { serialId: 0, isAllRecords: true })
      .pipe(map(data => data));
  }

  authorize(paymentChannelId: string, hierLabelIdReference: string, checkSum: string, hierarchyCriteria: string) {
    const dataBody = { paymentChannelId, hierLabelIdReference, checkSum, hierarchyCriteria };
    return this.apiService.post(environment.api_mpg_url + '/authorize', dataBody)
      .pipe(map(data => data));
  }

  fetchMerchantPaymentRoles(
    paymentChannelId: string, hierLabelIdReference: string,
    currencyCode: string, hierarchyCriteria: string, paymentInstrument: string) {
    const bodyData = { paymentChannelId, hierLabelIdReference, hierarchyCriteria, currencyCode, paymentInstrumentType: paymentInstrument };
    return this.apiService.post(environment.api_mpg_url + '/fetchMerchantPaymentRoles', bodyData)
      .pipe(map(data => data));
  }


  createTokenAndFetchBankList(
    paymentChannelId: string, hierLabelIdReference: string,
    hierarchyCriteria: string, amountVal: string, taxAmount: string, currencyCode: string,
    timezone: string, applicationLabel: string,
    consumerMsisdn: string, customerEmailId: string,
    hierLabelId: any, taxIncluded: string, orderId: string,
    checkSumPayment: string,
    electronicGoodsIndicator: string, additionalData: string
  ) {
    const bodyData = {
      paymentChannelId, hierLabelIdReference,
      hierarchyCriteria, amount: amountVal, taxAmount, currencyCode,
      timezone, applicationLabel,
      consumerMsisdn, emailAddress: customerEmailId,
      hierLabelId, taxIncluded, orderId,
      checkSumPayment,
      electronicGoodsIndicator, additionalDetails: additionalData
    };
    return this.apiService.post(environment.api_mpg_url + '/createTokenAndFetchBankList', bodyData)
      .pipe(map(data => data));
  }

  initiateBankPayment(transactionId: any, bankCode: string, bankName: string) {
    const bodyData = { transactionId, bankCode, bankName };
    return this.apiService.post(environment.api_mpg_url + '/initiateBankPayment', bodyData)
      .pipe(map(data => data));
  }

  initiateInstructionBasedWalletTransaction(
    url: string, paymentChannelId: string, hierLabelIdReference: string,
    consumerMsisdn: string, hierarchyCriteria: string, amount: string,
    taxAmount: string, taxIncluded: string, currencyCode: string,
    timezone: string, customerEmailId: string, applicationLabel: string,
    orderId: string, checkSumPayment: string, electronicGoodsIndicator: string,
    additionalData: string, customerCode: string, splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = {
      paymentChannelId,
      hierLabelIdReference,
      consumerMsisdn,
      hierarchyCriteria,
      amount,
      taxAmount,
      taxIncluded,
      currencyCode,
      timezone,
      emailAddress: customerEmailId,
      applicationLabel,
      orderId,
      checkSumPayment,
      electronicGoodsIndicator, additionalDetails: additionalData,
      customerCode
    };

    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }
    return this.apiService.post(environment.api_mpg_wallet_url + `${url}`, bodyData)
      .pipe(map(data => data));
  }

  initiateWalletPayment(url: string,
    walletPayload: IWalletPayload, splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = walletPayload;

    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }

    return this.apiService.post(environment.api_mpg_wallet_url + `${url}`, bodyData)
      .pipe(map(data => data));
  }

  validateUPI(bodyData: IValidateUPIPayload) {
    return this.apiService.post(environment.api_mpg_url + '/validateUpi', bodyData)
      .pipe(map(data => data));
  }

  initiateUPIPayment(url: string,
    upiPayload: IUPIPayload, splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = upiPayload;

    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }

    return this.apiService.post(environment.api_mpg_wallet_url + `${url}`, bodyData)
      .pipe(map(data => data));
  }

  initiateNetbankingPayment(url: string,
    netbankingPayload: INetbankingPayload, splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = netbankingPayload;

    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }

    return this.apiService.post(environment.api_mpg_wallet_url + `${url}`, bodyData)
      .pipe(map(data => data));
  }

  initiateCardPayment(url: string,
    upiPayload: ICardPayload, splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = upiPayload;

    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }

    return this.apiService.post(environment.api_mpg_wallet_url + `${url}`, bodyData)
      .pipe(map(data => data));
  }

  checkEligibilityApiSimpl(eligibilityPayload: ISimpleEligibility) {
    return this.apiService.post(environment.api_mpg_url + "/checkEligibility", eligibilityPayload)
      .pipe(map(data => data));
  }

  simplInitiatePayment(simpleInitiatePayload) {
    return this.apiService.post(environment.api_mpg_url + "/unifiedInitiatePayment", simpleInitiatePayload)
      .pipe(map(data => data));
  }

  delink(delinkPayload) {
    return this.apiService.post(environment.api_mpg_url + "/DeLink", delinkPayload)
    .pipe(map(data => data));
  }

  confirmInstructionBasedWalletTransaction(
    url: any, consumerMsisdn: string, amount: string,
    taxAmount: string, taxIncluded: string, refTransactionId: string,
    applicationLabel: string, sessionId: any, additionalData: string,
    splitTxnInfo: ISplitTxnData
  ) {
    let bodyData: any = {
      consumerMsisdn, amount, taxAmount, taxIncluded,
      refTransactionId, applicationLabel, sessionId, additionalDetails: additionalData
    };

    if (splitTxnInfo) {
      bodyData = {
        ...bodyData,
        loyaltyFlag: fieldnames.YES, loyaltyAmount: splitTxnInfo.worthAmount.toString(), loyaltyPoints: splitTxnInfo.points.toString()
      };
    }
    return this.apiService.post(environment.api_mpg_wallet_url + `${url}`, bodyData)
      .pipe(map(data => data));
  }

  sendOtp(
    sessionId: string, paymentChannelId: string, hierLabelIdReference: string,
    mobilePrefix: string, mobileNumber: string, emailAddress: string,
    hierarchyCriteria: string
  ) {
    return this.apiService.post(environment.api_mpg_url + '/fetchCardTokens', {
      paymentChannelId, hierLabelIdReference, sessionId, mobilePrefix,
      mobileNumber, emailAddress, hierarchyCriteria
    })
      .pipe(map(data => data));
  }

  verifyOtp(
    otp: string, sessionId: string, paymentChannelId: string,
    hierLabelIdReference: string, mobilePrefix: string, mobileNumber: string,
    emailAddress: string, hierarchyCriteria: string, transactionAmount: string, isEmi: boolean) {
    const req: any = {
      paymentChannelId, hierLabelIdReference, sessionId,
      mobilePrefix, mobileNumber, emailAddress, hierarchyCriteria, otp
    };
    if (isEmi) {
      req.emiCheck = fieldnames.YES,
        req.amount = transactionAmount;
    }
    return this.apiService.post(environment.api_mpg_url + '/fetchCardTokens', req)
      .pipe(map(data => data));
  }
  deleteCardTokens(token) {
    return this.apiService.post(environment.api_mpg_url + '/deleteCardTokens', { token })
      .pipe(map(data => data));
  }
  getbusinessPromotion(businessId: string) {
    return this.apiService.post(environment.caching_api_url + '/cacheCommonValidation/getbusinessPromotion', businessId)
      .pipe(map(data => data));
  }

  proceedToPay(
    cardNumber: string, cardExpiry: string, cardNameCredit: string, cvvCredit: string, saveCard: string, additionalData: string,
    sessionId: any, paymentInfo: IPaymentInfo, hierdata: IHierarchyData,
    emiPayload: { plan: EMIPlanBean, type: string }, fallback: string
  ) {
    const orderId = paymentInfo.orderId;
    let endPoint = '';
    let body: any = {
      paymentChannelId: hierdata.paymentChannelId, hierLabelIdReference: hierdata.hierLabelIdReference,
      transactionType: 'sale', transactionAmount: paymentInfo.amount.toString(), pan: cardNumber,
      cardholderName: cardNameCredit, expiryDate: cardExpiry, cardVerificationValue: cvvCredit,
      taxAmount: '1', transactionQualifier: 'CNP_CREDIT', electronicGoodsIndicator: 'D',
      orderId, customerCode: paymentInfo.mobileNumber,
      checkSumPayment: 'fd63ab7f20d1176e6c996046a5322aed66405321a9b0263084d7f40dcdb5f55b',
      shippingDate: '12-May-2015', initialAmount: paymentInfo.amount.toString(), hierarchyCriteria: hierdata.hierarchyCriteria,
      addCardCheck: saveCard, currencyCode: paymentInfo.currencyCode, timezone: utcTimeZone(),
      recurringFlag: 'N', freeTrialFlag: 'N', mobile: paymentInfo.mobileNumber, email: paymentInfo.mailId,
      mobilePrefix: paymentInfo.mobilePreFix, taxIncluded: '2', sessionId, additionalDetails: additionalData, fallback: fallback
    };
    if (emiPayload) {
      endPoint = '/invoiceEmiSale';
      body = {
        ...body,
        transactionType: 'emi',
        emiPeriod: emiPayload.plan.tenure,
        emiProcessingFees: emiPayload.plan.processingFee,
        emiProcessingFeesIndicator: emiPayload.plan.processingFeeIndicator,
        emiInterestRate: emiPayload.plan.interestRate,
        emiOrderAmount: paymentInfo.amount.toString(),
        transactionAmount: emiPayload.plan.totalAmount,
      };
    } else {
      endPoint = '/invoice/sale';
    }
    return this.apiService.post(environment.api_mpg_url + endPoint, body)
      .pipe(map(data => data));
  }

  setpaidStatus(invoiceId) {
    return this.apiService.post(environment.api_url + '/invoice/paid/', invoiceId)
      .pipe(map(data => data));
  }

  proceedToPayToken(
    cvvCredit: string, token: string, additionalData: string, sessionId: any, paymentInfo: IPaymentInfo, hierData: IHierarchyData,
    emiPayload: { plan: EMIPlanBean, type: string }, fallback: string) {
    const orderId = paymentInfo.orderId;
    let endPoint = '';
    let body: any = {
      paymentChannelId: hierData.paymentChannelId, hierLabelIdReference: hierData.hierLabelIdReference,
      transactionType: 'sale', transactionAmount: paymentInfo.amount.toString(), pan: token,
      cardVerificationValue: cvvCredit, taxAmount: '1', transactionQualifier: 'CNP_CREDIT',
      electronicGoodsIndicator: 'D', orderId, customerCode: paymentInfo.mobileNumber,
      checkSumPayment: 'fd63ab7f20d1176e6c996046a5322aed66405321a9b0263084d7f40dcdb5f55b',
      shippingDate: '12-May-2015',
      initialAmount: paymentInfo.amount.toString(), hierarchyCriteria: hierData.hierarchyCriteria,
      currencyCode: paymentInfo.currencyCode,
      timezone: utcTimeZone(), recurringFlag: 'N', freeTrialFlag: 'N', mobile: paymentInfo.mobileNumber,
      email: paymentInfo.mailId, mobilePrefix: paymentInfo.mobilePreFix, taxIncluded: '2', sessionId, additionalDetails: additionalData,
      fallback: fallback
    };
    if (emiPayload) {
      endPoint = '/invoiceEmiSale';
      body = {
        ...body,
        transactionType: 'emi',
        emiPeriod: emiPayload.plan.tenure,
        emiProcessingFees: emiPayload.plan.processingFee,
        emiProcessingFeesIndicator: emiPayload.plan.processingFeeIndicator,
        emiInterestRate: emiPayload.plan.interestRate,
        emiOrderAmount: paymentInfo.amount.toString(),
        transactionAmount: emiPayload.plan.totalAmount,
      };
    } else {
      endPoint = '/invoice/sale';
    }
    return this.apiService.post(environment.api_mpg_url + endPoint,
      body
    )
      .pipe(map(data => data));
  }

}
