
import * as $ from "jquery";
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxQrcodeReaderModule } from 'ngx-qrcode-reader';

import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

import { CoreModule } from 'src/app/core/core.module';
import {
  MaskDirective, DigitOnlyDirective
} from 'src/app/core';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent, FormatTimePipe } from 'src/app/app.component';



import { NotFoundComponent } from './not-found/not-found.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LightboxFooterComponent } from './lightbox-footer/lightbox-footer.component';

import { SessionOutComponent } from './session-out/session-out.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';

import { HomeComponent } from './home/home.component';
import { BankComponent } from './bank/bank.component';
import { WalletComponent } from './wallet/wallet.component';
import { TransactionCompleteComponent } from './transaction-complete/transaction-complete.component';
import { CardComponent } from './card/card.component';
import { UPIComponent } from './upi/upi.component';
import { QRCodeComponent } from './qrcode/qrcode.component';

import { environment } from '../environments/environment';
import { DemoComponent} from 'src/app/demo/demo.component';

import { EmiPlansComponent } from './emi-plans/emi-plans.component';

import { LoyaltyPaymentComponent } from './loyalty-payment/loyalty-payment.component';
import { LoaderComponent } from './loader/loader.component';
import { PayLaterComponent } from "./pay-later/pay-later.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  useBothWheelAxes: true,
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    FooterComponent,
    HeaderComponent,
    SessionOutComponent,
    PaymentSummaryComponent,
    HomeComponent,
    LightboxFooterComponent,
    BankComponent,
    WalletComponent,
    TransactionCompleteComponent,
    CardComponent,
    UPIComponent,
    QRCodeComponent,
    DemoComponent,
    EmiPlansComponent,
    DigitOnlyDirective,
    MaskDirective,
    LoyaltyPaymentComponent,
    LoaderComponent,
    FormatTimePipe,
    PayLaterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ServiceWorkerModule.register('serviceWorker.js', { enabled: environment.production }),
    PerfectScrollbarModule,
    NgxQRCodeModule,
    NgxQrcodeReaderModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


