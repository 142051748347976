export const environment = {
  production: false,
  api_url: 'https://dev2-test.payplus-comviva.com/invoice-payments',
  api_auth_url: 'https://dev2-test.payplus-comviva.com/authorization',
  smart_routing: 'https://dev2-test.payplus-comviva.com/smart-routing/smart-routing',
  caching_api_url: 'https://dev2-test.payplus-comviva.com/caching',
  api_mpg_url: 'https://dev2-test.payplus-comviva.com/ecom',
  api_mpg_wallet_url: 'https://dev2-test.payplus-comviva.com',
  ecomtoken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imp3cy1rZXktcHVibGljLTEifQ.eyJzY29wZSI6WyJHRVRfVVNFUl9BTkRfQUNDT1VOVF9ERVRBSUxTIiwiU0VMRlNFVEFVVEhGQUNUT1IiLCJGRVRDSF9VU0VSX1FVRVNUSU9OIiwiVFhOQ09SUkVDVCIsIkFUTUNBU0hPVVQiLCJBVE1DQVNIT1VUX1Y0IiwiQURNVFhOUkVGSUQiXSwiZXhwIjoxNzQ0NzQwMzY4LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiJiYTRmOWQxZC00YTJmLTQ1YTUtYWIwZS1lMmI3M2VhMjE1OTQiLCJjbGllbnRfaWQiOiJDb3JlV2ViIn0.LInh8oQAyVeZOmCITRjEphLer4UjEg9qRg2DTZ9y5O44MruC3sP1S1iYqudPYqnT_J1W46hZOIxRM3f4kkFCeKB-mnyh9fvVcMXaMy60y5w4Zt5ZZeAVywoauA2NmEWNlgZT3Ar7d952tLGGASQwxp2EFF93FEY9S0-4JQZ_t_sZkIcegL8LDkZnl6Ef0vWDHOTUU1P0rqi8wvuYDMBDcBsrcG31gCslkXjq6qk-DXuyvjQogQlyDay1euchpBN-1wxLtfETyRkjtLe5CiZctWIAhyLn32rY8vpez3kKmf1_z5v5U8q6fvSM81Hyvy8RBVgKtkdwV77Q2fxRn1Q7Pg',
  invtoken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imp3cy1rZXktcHVibGljLTEifQ.eyJzY29wZSI6WyJHRVRfVVNFUl9BTkRfQUNDT1VOVF9ERVRBSUxTIiwiU0VMRlNFVEFVVEhGQUNUT1IiLCJGRVRDSF9VU0VSX1FVRVNUSU9OIiwiVFhOQ09SUkVDVCIsIkFUTUNBU0hPVVQiLCJBVE1DQVNIT1VUX1Y0IiwiQURNVFhOUkVGSUQiXSwiZXhwIjoxNzQ0NzQwMzY4LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiJiYTRmOWQxZC00YTJmLTQ1YTUtYWIwZS1lMmI3M2VhMjE1OTQiLCJjbGllbnRfaWQiOiJDb3JlV2ViIn0.LInh8oQAyVeZOmCITRjEphLer4UjEg9qRg2DTZ9y5O44MruC3sP1S1iYqudPYqnT_J1W46hZOIxRM3f4kkFCeKB-mnyh9fvVcMXaMy60y5w4Zt5ZZeAVywoauA2NmEWNlgZT3Ar7d952tLGGASQwxp2EFF93FEY9S0-4JQZ_t_sZkIcegL8LDkZnl6Ef0vWDHOTUU1P0rqi8wvuYDMBDcBsrcG31gCslkXjq6qk-DXuyvjQogQlyDay1euchpBN-1wxLtfETyRkjtLe5CiZctWIAhyLn32rY8vpez3kKmf1_z5v5U8q6fvSM81Hyvy8RBVgKtkdwV77Q2fxRn1Q7Pg',
  loyalty_url: 'https://dev2-test.payplus-comviva.com/loyalty/loyalty',  
  service_integrator_url: 'https://dev2-test.payplus-comviva.com/service-integrator/ccavenue',
  redirectHtmlFlag: 'TRUE',
  retryCount: '2',
  successTimeout: '2',
  failureTimeout: '5'
};
