import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import shajs from 'sha.js';

import { TranslateService } from '@ngx-translate/core';

import {
  ResponseCode, ResponseContextPath, ILightBoxData, IAdditionalDetails, IPaymentInfo, ISessionData,
  IHierarchyData, PageTypes, CnpdataService, HeaderService, Paymode, IPaymode,
  utcTimeZone, WalletScreens, UserdataService,
  PaymentRolesService, ITxnData, ISplitTxnData, LightboxdataService, IWalletPayload
} from 'src/app/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WalletComponent implements OnInit {
  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();
  childWindow: Window;

  @ViewChild('mobileField', null) set mobileField(element: ElementRef) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  selectedOption: string;
  paymodeData: IPaymode = Paymode;

  referenceID = '';
  transactionId = '';
  referenceStatus = false;
  initiatecontextPath = '';
  confirmcontextPath = '';
  showresponseMessage = '';
  additionalData: string;
  currency = '';
  amount = '';
  mobileNo = '';
  showretrypage = false;
  walletTransactionId: string;
  mobilePreErrorMsg = '';
  lightboxEnable: boolean;
  translateText: any[] = [];
  initiateContextPath = '';
  confirmContextPath = '';
  paymentFlowType = '';
  paymentInstrumentType: any;
  wallets: any = {};
  selectedImage = '';
  selectedValue = { wallet: '' };
  currentScreen = WalletScreens.HOME;
  paymentInfo: IPaymentInfo;
  hierarchyData: IHierarchyData;
  sessionData: ISessionData = {} as ISessionData;
  lightBoxData: ILightBoxData = {} as ILightBoxData;
  WalletScreens = WalletScreens;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  PageTypes = PageTypes;

  txnStatus = false;
  txnData: ITxnData = {} as ITxnData;

  splitTxnInfo: ISplitTxnData = null;
  splitTxnStatus = false;
  referenceOrderId = '';
  Object = Object;
  showWallets = false;
  walletsToDisplay: Array<any> = [];
  currentWallet = 0;
  isFallback = false;
  skipOrderIdCheckFlag: boolean;

  constructor(
    private cnpdataService: CnpdataService,
    private translateService: TranslateService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
    private router: Router,
    private lightboxdataService: LightboxdataService,
    private paymentRole: PaymentRolesService
  ) {
    this.sessionData = this.userdataService.getSessionData();
    this.translateService.get(['checkoutModule.invalidmobile']).subscribe((resp) => {
      this.translateText = resp;
    });
    this.headerService.getHeaderCurrency().subscribe(resp => this.currency = resp);
    this.headerService.getSplitTxnStatus().subscribe(resp => this.splitTxnStatus = resp);
    this.lightboxEnable = this.headerService.getPageType() === this.PageTypes.LB;
  }

  ngOnInit() {
    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
      this.additionalDetails = this.userdataService.getAdditionalData();
      this.amount = this.lightBoxData.transactionAmount;
      this.fetchRole();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
      this.hierarchyData = this.userdataService.getHierarchyData();
      if (this.paymentInfo && this.hierarchyData) {
        this.fetchRole();
        this.amount = this.paymentInfo.amount.toString();
      } else {
        this.router.navigate(['sessionout']);
      }
    }
  }

  doNext() {
    const walletNames = Object.keys(this.wallets).sort();
    for (this.currentWallet = 0; this.currentWallet < walletNames.length; this.currentWallet++) {
      this.walletsToDisplay.push(this.wallets[walletNames[this.currentWallet]]);
    }
  }

  retry(changePayload: boolean): void {
    if (this.lightboxEnable) {
      this.lightBoxData = this.userdataService.getLightboxInfo();
    } else {
      this.paymentInfo = this.userdataService.getPaymentInfo();
    }
    if (changePayload) {
      this.gotoHome(true);
    } else {
      if (this.initiateContextPath === ResponseContextPath.initiatePayment) {
        this.currentScreen = this.WalletScreens.HOME;
      } else {
        this.currentScreen = this.WalletScreens.MSISDN;
      }
    }
    this.headerService.changeHeaderStatus(true);
    this.skipOrderIdCheckFlag = true;
  }

  reEnter(): void {
    if (this.lightboxEnable) {
      this.paymentRole.getRetryOrderId(
        this.lightBoxData.callbackurl, this.lightBoxData.orderId,
        this.lightBoxData.transactionType, this.lightBoxData.transactionAmount
      ).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.lightBoxData.orderId = resp.orderId;
          this.lightBoxData.checkSumPayment = resp.checksumpayment;
          this.userdataService.setLightboxInfo(this.lightBoxData);
          this.currentScreen = this.WalletScreens.MSISDN;
        } else {
          this.handleError();
        }
      }, error => {
        console.error(error);
        this.router.navigate(['failure']);
      });
    } else {
      this.cnpdataService.getPaymentDatabtBatchId(this.paymentInfo.hashid).subscribe(resp => {
        if (resp.responseCode === ResponseCode.successResponseCode) {
          this.paymentInfo.orderId = resp.jsonBody.orderId;
          this.userdataService.setPaymentInfo(this.paymentInfo);
          this.currentScreen = this.WalletScreens.MSISDN;
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    }
  }

  fetchRole() {
    const roles = this.paymentRole.getRoles();
    Object.keys(roles).forEach(role => {
      if (roles[role].paymentInstrumentType === 'Wallet') {
        this.wallets[role] = roles[role];
        this.wallets[role].role = role;
        this.initiateContextPath = roles[role].initiatePaymentContextPath;
      }
    });
    this.doNext();
  }

  async loadWalletResponseHTML (htmlResponse) {
    if (this.childWindow) {
      try {
          this.renderHtmlContent(htmlResponse);
      } catch(error) {
        this.retryPaymentWithfallback();
      } 
    }
  }

  renderHtmlContent(htmlResponse) {
    if (htmlResponse) {
      this.childWindow.document.write(htmlResponse);
      let timer = setInterval(() => {
        this.loadingTrue();
        if (this.childWindow.closed) {
          this.txnStatus = false;
          this.handlePolling();
          clearInterval(timer);
          this.childWindow = null;
        }
      }, 50);
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  retryPaymentWithfallback() {
    if(!this.isFallback) {
      this.updateTransactionStatus();
      this.initiatePayment('Y');
    } else {
      this.childWindow && this.childWindow.close();
      this.handleError();
    }
  }

  updateTransactionStatus() {
    this.lightboxdataService.updateTransStatus(this.transactionId).subscribe(resp => {
    })
  }

  generateCheckSumforTxnStatus(txnId) {
    let checksumInputStringforTxnStatus = "";
    checksumInputStringforTxnStatus = checksumInputStringforTxnStatus.concat(txnId).concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputStringforTxnStatus).digest('hex');
  }

  handlePolling() {
    const checkSumPaymentforTxnStatus = this.generateCheckSumforTxnStatus(this.transactionId);
    this.lightboxdataService.otpTransStatusForWeb(this.transactionId, checkSumPaymentforTxnStatus).subscribe(resp => {
      if (resp) {
        const { ecommPaymentResponseList = [] } = resp;
        const ecommPaymentResponse = ecommPaymentResponseList[0] || {};
        if (ecommPaymentResponse.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }
      }
      if (!resp) {
        this.txnStatus = false;
      }
      if(this.txnStatus && !this.lightboxEnable) {
        this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
          this.showTxnComplete();
        });
      } else {
        this.showTxnComplete();
      }
    },
      err => {
        if (err.status === 0 || err.status === 500 || err.status === 400) {
          this.txnStatus = false;
          this.showTxnComplete();
        }
      });
  }

  loadingTrue() {
    this.headerService.changeLoaderStatus(true);
  }

  loadingFalse() {
    this.headerService.changeLoaderStatus(false);
  }

  generateRef() {
    this.referenceID = '';
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }
    if (this.lightboxEnable) {
      this.cnpdataService.initiateInstructionBasedWalletTransaction(
        this.initiateContextPath, this.lightBoxData.paymentChannelId,
        this.lightBoxData.hierLabelIdReference, this.mobileNo,
        this.lightBoxData.hierarchyCriteria,
        this.lightBoxData.transactionAmount, '1', '2',
        this.lightBoxData.currency, utcTimeZone(), this.lightBoxData.email,
        this.selectedOption, this.lightBoxData.orderId,
        this.lightBoxData.checkSumPayment, 'D', null, this.lightBoxData.customerCode,
        this.splitTxnInfo
      ).subscribe(resp => {
        if (resp.referenceOrderId) {
          this.referenceOrderId = resp.referenceOrderId;
        }
        if (resp.referenceID || resp.refTransactionId) {
          if (resp.referenceID) {
            this.referenceID = resp.referenceID;
          }
          if (resp.refTransactionId) {
            this.referenceID = resp.refTransactionId;
          }
        }
        if (resp.ecommPaymentResponseList) {
          resp.ecommPaymentResponseList.forEach(element => {
            if (element.referenceID) {
              this.referenceID = element.referenceID;
            }
            if (element.refTransactionId) {
              this.referenceID = element.refTransactionId;
            }
            if (element.referenceOrderId) {
              this.referenceOrderId = element.referenceOrderId;
            }
          });
        }

        if (resp.ecommPaymentResponseList && resp.ecommPaymentResponseList.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }

        if (!this.referenceID) {
          this.handleError();
        } else {
          this.referenceStatus = true;
        }
      }, error => this.handleError(error));
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      this.cnpdataService.initiateInstructionBasedWalletTransaction(
        this.initiateContextPath, this.hierarchyData.paymentChannelId,
        this.hierarchyData.hierLabelIdReference, this.mobileNo,
        this.hierarchyData.hierarchyCriteria,
        this.paymentInfo.amount.toString(), '1', '2',
        this.paymentInfo.currencyCode, utcTimeZone(), this.paymentInfo.mailId,
        this.selectedOption, this.paymentInfo.orderId,
        'fd63ab7f20d1176e6c996046a5322aed66405321a9b0263084d7f40dcdb5f55b',
        'D', this.additionalData, this.mobileNo, null
      ).subscribe(resp => {
        this.referenceOrderId = resp.referenceOrderId;
        if (resp.referenceID || resp.refTransactionId) {
          this.referenceStatus = true;
          if (resp.referenceID) {
            this.referenceID = resp.referenceID;
          } else {
            this.referenceID = resp.refTransactionId;
          }
        } else {
          this.handleError();
        }
      }, error => this.handleError(error));
    }
  }

  generateCheckSum(orderId, amount) {
    let checksumInputString = "";
    checksumInputString = checksumInputString.concat(amount).concat(orderId).concat("1").concat("D").concat("A}XIgrH6!M-_un7{");
    return shajs('sha256').update(checksumInputString).digest('hex');
  }

  initiatePayment(fallback) {
    this.transactionId = '';
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }

    this.isFallback = fallback === "Y";

    let height = 500;
    let width = screen.width * 0.6;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;

    // if (!this.isFallback) {
    //   this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
    //   this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
    // }

    const domainName = this.extractDomainFromUrl(environment.api_url);
    const primaryWebhookURL = `https://${domainName}/service-integrator/txnstatus/webhook`;
    const fallbackWebhookURL = `https://${domainName}/service-integrator/txnstatus/fallback/webhook`;

    if (this.lightboxEnable) {

      const { customerFirstName = "", customerLastName = "" } = this.additionalDetails || {};
      let walletPayload: IWalletPayload = {
        paymentChannelId: this.lightBoxData.paymentChannelId,
        hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
        consumerMsisdn: this.mobileNo,
        hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
        amount: this.lightBoxData.transactionAmount,
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.lightBoxData.currency,
        timezone: "India Standard Time",
        emailAddress: this.lightBoxData.email,
        applicationLabel: this.selectedOption,
        orderId: this.lightBoxData.orderId,
        checkSumPayment: this.lightBoxData.checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: JSON.stringify(this.additionalDetails),
        customerCode: this.lightBoxData.customerCode,
        customerFirstName,
        customerLastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL : "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };
      this.cnpdataService.initiateWalletPayment(
        this.initiateContextPath, walletPayload,
        this.splitTxnInfo
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        if (response.referenceOrderId) {
          this.referenceOrderId = response.referenceOrderId;
        }
        if (response.transactionId) {
          this.transactionId = response.transactionId;
        }
        if (response.responseCode === ResponseCode.successResponseCode) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }

        if (!this.transactionId || this.txnStatus == false)  {
          this.childWindow && this.childWindow.close();
          this.handleError();
        } else {
          this.referenceStatus = true;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadWalletResponseHTML(response.callBackUrl);
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error);
      });
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });

      const checkSumPayment = this.generateCheckSum(this.paymentInfo.orderId, this.paymentInfo.amount.toString());
      let walletPayload: IWalletPayload = {
        paymentChannelId: this.hierarchyData.paymentChannelId,
        hierLabelIdReference: this.hierarchyData.hierLabelIdReference,
        consumerMsisdn: this.mobileNo,
        hierarchyCriteria: this.hierarchyData.hierarchyCriteria,
        amount: this.paymentInfo.amount.toString(),
        taxAmount: '1',
        taxIncluded: '2',
        currencyCode: this.paymentInfo.currencyCode,
        timezone: "India Standard Time",
        emailAddress: this.paymentInfo.mailId,
        applicationLabel: this.selectedOption,
        orderId: this.paymentInfo.orderId,
        checkSumPayment,
        electronicGoodsIndicator: 'D',
        additionalDetails: this.additionalData,
        customerCode: this.mobileNo,
        customerFirstName: this.paymentInfo.firstName,
        customerLastName: this.paymentInfo.lastName,
        fallback,
        method: this.paymentInstrumentType,
        primaryWebhookURL,
        fallbackWebhookURL,
        ...(this.skipOrderIdCheckFlag ? { skipOrderIdCheck: true } : {}),
        merchantRedirectURL : "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
      };

      this.cnpdataService.initiateWalletPayment(
        this.initiateContextPath, walletPayload, null
      ).subscribe(resp => {
        const { initiatePaymentResponses = [] } = resp || {};
        if (initiatePaymentResponses.length > 0) {
          const response = initiatePaymentResponses[0];
          if (response.redirectURL) {
            if (environment.redirectHtmlFlag == 'TRUE') {
              window.open(response.redirectURL);
              return;
            }
            else {
              if (!this.isFallback) {
                this.childWindow = window.open("", '_blank', `toolbar=0, scrollbar=yes, width=${width}, height=${height}, left=${left}, top=${top}`);
                this.childWindow.document.write("<style> body { justify-content: center; align-items: center; flex-direction: column; display: flex; padding: 40px } .loader { margin-top: 20px; border: 5px solid #f3f3f3; border-radius: 50%; border-top: 5px solid #3498db; width: 30px; height: 30px; -webkit-animation: spin 2s linear infinite; /* Safari */ animation: spin 2s linear infinite; } /* Safari */ @-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); } } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } </style> </head> <body> <div class='loader'></div> </body> </html>");
              }
            }
          }
        }
        if (initiatePaymentResponses.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const htmlResponse = initiatePaymentResponses.filter(e => e.hasOwnProperty("modId")) || [];
        if (htmlResponse.length <= 0) {
          this.childWindow && this.childWindow.close();
          this.handleError();
          return;
        }
        const response = htmlResponse[0];
        this.referenceOrderId = response.referenceOrderId;
        if (response.transactionId && response.responseCode === ResponseCode.successResponseCode) {
          this.referenceStatus = true;
          this.transactionId = response.transactionId;
          this.txnData.transactionId = this.transactionId;
          if (this.splitTxnStatus) {
            this.txnData.referenceOrderId = this.referenceOrderId;
          } else {
            this.txnData.transactionId = this.transactionId;
          }
          this.loadWalletResponseHTML(response.callBackUrl);
        } else {
          this.childWindow && this.childWindow.close();
          this.handleError();
        }
      }, error => {
        this.childWindow && this.childWindow.close();
        this.handleError(error);
      });
    }
  }

  private extractDomainFromUrl(url: string): string {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return '';
    }
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.WalletScreens.COMPLETE;
    this.loadingFalse()
  }

  confirmPayment() {
    if (this.splitTxnStatus) {
      this.splitTxnInfo = this.userdataService.getSplitTxnInfo();
    }
    if (this.lightboxEnable) {
      this.cnpdataService.confirmInstructionBasedWalletTransaction(
        this.confirmContextPath,
        this.mobileNo,
        this.lightBoxData.transactionAmount, '1', '2',
        this.referenceID,
        this.selectedOption, this.sessionData.sessionId,
        JSON.stringify(this.additionalDetails), this.splitTxnInfo
      ).subscribe(resp => {
        this.txnData.responseCode = resp.responseCode;
        this.txnData.responseMessage = resp.responseMessage;
        this.txnData.referenceOrderId = this.referenceOrderId;
        this.txnData.recurrentToken = resp.recurrentToken;
        if (resp.responseCode === ResponseCode.walletSuccess) {
          this.txnStatus = true;
        } else {
          this.txnStatus = false;
        }
        this.showTxnComplete();
      }, error => {
        console.error(error);
        this.txnStatus = false;
        this.showTxnComplete();
      });
    } else {
      this.additionalData = JSON.stringify({ electronicGoodsIndicator: 'D', invoiceId: this.paymentInfo.id });
      this.cnpdataService.confirmInstructionBasedWalletTransaction(
        this.confirmContextPath,
        this.mobileNo,
        this.paymentInfo.amount.toString(), '1', '2',
        this.referenceID,
        this.selectedOption, this.sessionData.sessionId, this.additionalData, null
      ).subscribe(resp => {
        this.txnData.responseCode = resp.responseCode;
        this.txnData.responseMessage = resp.responseMessage;
        this.txnData.referenceOrderId = this.referenceOrderId;
        this.txnData.recurrentToken = resp.recurrentToken;
        if (resp.responseCode === ResponseCode.walletSuccess) {
          this.cnpdataService.setpaidStatus(this.paymentInfo.id).subscribe(resp1 => {
            this.txnStatus = true;
            this.showTxnComplete();
          });
        } else {
          this.txnStatus = false;
          this.showTxnComplete();
        }
      }, error => {
        console.error(error);
        this.txnStatus = false;
        this.showTxnComplete();
      });
    }
  }


  setradio(wallet: string): void {
    this.selectedOption = wallet;
    this.selectedValue.wallet = wallet;
    this.selectedImage = this.getWalletLogoPath(this.wallets[wallet].logo);
    this.initiateContextPath = this.wallets[wallet].initiatePaymentContextPath;
    this.confirmContextPath = this.wallets[wallet].confirmPaymentContextPath;
    this.paymentFlowType = this.wallets[wallet].paymentFlowType;
    this.paymentInstrumentType = this.wallets[wallet].paymentInstrumentType;
  }

  getWalletLogoPath(logoUrl: string): string {
    const url = new URL(logoUrl);
    const pathname = url.pathname;
    const filenameMatch = pathname.match(/\/([^\/?]+)$/);
    const filename = filenameMatch ? filenameMatch[1] : null;
    const cleanedFilename = filename ? filename.replace(/^paymentInstrument_/i, '').replace(/\.action$/i, '') : null;
    const logoPath = cleanedFilename ? `assets/images/${cleanedFilename}.png` : null;
    return logoPath;
  }

  goToInstructions() {
    if (this.initiateContextPath === ResponseContextPath.initiatePayment) {
      this.initiatePayment('N');
    } else {
      this.goTo(WalletScreens.INSTRUCTIONS);
    }
  }

  goTo(screen: string): void {
    if (screen === this.WalletScreens.INSTRUCTIONS) {
      this.generateRef();
    }
    if (screen === this.WalletScreens.MSISDN) {
      this.mobileNo = '';
    }
    this.currentScreen = screen;
  }

  gotoWalletPage() {
    this.mobileNo = '';
  }

  gotoHome(clear?: boolean): void {
    this.showHome.emit(clear);
  }

  navigatebacktopaymantpage() {
    this.showretrypage = false;

  }

  ismobileValid() {
    if (this.mobileNo === '') {
      return true;
    }
    this.mobilePreErrorMsg = '';
    if ((this.mobileNo.length > 0 && this.mobileNo.length < 4) || this.mobileNo.length > 15) {
      this.mobilePreErrorMsg = this.translateText['checkoutModule.invalidmobile'];
      return false;
    }
  }

  handleError(error?: any): void {
    if(this.initiateContextPath === ResponseContextPath.initiatePayment) {
      this.txnStatus = false;
      this.showTxnComplete();
      return;
    }
    if (error) {
      console.error(error);
    }
    this.router.navigate(['error']);
  }
}
